import axios from "axios";

const baseUrl = import.meta.env.VITE_API_SERVER_URL;

export const HttpMethod = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete"
};

export const apiFetch = async (apiUrl, arg) => {
  const url = `${baseUrl}${apiUrl}`;
  const headers = {
    "Content-Type": "application/json",
    ...arg.headers
  };
  try {
    return await axios({ url, ...arg, headers }).then((res) => res.data);
  } catch (error) {
    console.error("Error fetching todos:", error);
    return error.response;
  }
};
