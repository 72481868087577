import { SERVER_URL } from "../config/constant";
export const stringConstant = {
  FAILED_GET_DATA: "Failed to get data",
};

export const webAPI = {
  getPublishedAiList: SERVER_URL + "/api/published-ai-list",
  getPublishedById: SERVER_URL + "/api/published-ai-by-id",
  sendreview: SERVER_URL + "/api/sendreview",
  getreviews: SERVER_URL + "/api/getallreviews",
  addbadge: SERVER_URL + "/api/addbadge",
  sendemail: SERVER_URL + "/api/sendemail",
};

export const EXTRA_URLs = {
  LOGIN_PATH: `${import.meta.env.VITE_SERVER_URL}/login`,
  GET_STARTED_PATH: `${import.meta.env.VITE_SERVER_URL}/register`,
  TAISK_LABS: "https://labs.taisk.com",
  CREATORS: "https://labs.taisk.com/creators",
  DEMO: "https://labs.taisk.com/building-blocks",
  SOLUTION_STATUS: "https://interactivetutor1.statuspage.io/",
  TAISK_PARTNER_SIGNUP: "https://taisk.getrewardful.com/signup",
  SUPPORT: "https://taisk.notion.site/TAISK-Documentation-fb6a3f188fa54da1aa1be634bd37a3a9",
  DATA_SECURITY: "https://www.labs.taisk.com/data-security",
  CUSTOM_AI_DEVELOPMENT: "https://labs.taisk.com/custom",
};
