import Badge from "../../common/components/Forms/Badge";
import "./styles.scss";
import PlusIcon from "./PlusIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Tooltip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";
import { DocumentTextIcon, StarIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AiDescriptionDetails from "./components/AiDescriptionDetails";
import RelatedAiList from "../../common/components/RelatedAiList";
import AiReviewDetails from "./components/AiReviewDetails";
import { defaultLogoUrl, defaultUserUrl } from "../../config/constant";
import image0 from "../../assets/0.svg";
import image1 from "../../assets/1.svg";
import image2 from "../../assets/2.svg";
import image3 from "../../assets/3.svg";
import image4 from "../../assets/4.svg";
import image5 from "../../assets/5.svg";
import image6 from "../../assets/6.svg";
import image7 from "../../assets/7.svg";
import image8 from "../../assets/8.svg";
import bronze from "../../assets/bronzeaward.svg";
import silver from "../../assets/silveraward.svg";
import gold from "../../assets/goldaward.svg";
import { webAPI } from "../../utils/constants";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import PageLoader from "../../common/components/PageLoader";
import MarkdownRenderer from "../../components/MarkdownRenderer";

const Badges = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  bronze,
  silver,
  gold,
];

const ToolTips = [
  "Awarded to AI Bots demonstrating unique and creative teaching methods.",
  "For AI Bots that effectively navigate and utilize digital resources in the classroom.",
  "Awarded to AI Bots that foster teamwork and collaboration among students.",
  "For AI Bots showcasing exceptional proficiency in integrating technology into education.",
  "For AI Bots showcasing exceptional proficiency in integrating technology into education.",
  "For AI Bots specializing in teaching and enhancing language skills.",
  "Given to AI Bots demonstrating excellence in teaching complex mathematical concepts.",
  "For AI Bots that inspire curiosity and innovation in science subjects.",
  "Awarded to AI Bots that effectively incorporate cultural awareness and diversity into learning.",
  "5 Downloads",
  "20 Downloads",
  "50 Downloads",
];

const AIBotPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const currentId = useRef(id);

  const [aibot, setAIBot] = useState(location.state?.aibot);
  const isPackage = location.state?.isPackage;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getAiBot = async () => {
      if (aibot && id === currentId.current) {
        setLoading(false);
        return;
      }
      currentId.current = id;

      const res = await axios.get(
        `${webAPI.getPublishedById}?library_id=${id}`,
      );

      setLoading(false);
      if (res?.data?.data) {
        setAIBot(res?.data?.data);
      }
    };
    getAiBot();
  }, [id]);

  const [email, setEmail] = useState("");
  const [activeTab, setActiveTab] = useState("description");
  const [isAddBadgeModal, setIsAddBadgeModal] = useState(false);
  const [isAddLibraryModal, setIsAddLibraryModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [chats, setChats] = useState([]);

  const getAllReviews = (bot) => {
    axios
      .post(webAPI.getreviews, { chat: bot })
      .then((res) => setReviews(res.data.data));
  };

  const getPublishChats = (menu) => {
    axios
      .post(webAPI.getPublishedAiList, {
        menu,
        subMenu: 0,
        sortby: 0,
        page: 1,
        perpage: 6,
      })
      .then((res) => {
        setChats(res.data.data);
      })
      .catch((err) => {
        setChats([]);
        console.error(err);
      });
  };

  const aibotInfo = useMemo(() => {
    window.scrollTo(0, 0);
    if (!aibot) return {};
    getAllReviews(aibot);
    getPublishChats(aibot?.menu);
    return aibot;
  }, [aibot]);

  const categories = useSelector((state) => state.marketplace.categories);

  const libraryDetails = aibot;

  let newBadges = libraryDetails?.badge;
  const handleAddBadge = (id) => {
    axios.post(webAPI.addbadge, { chat: aibotInfo, badge: id }).then((res) => {
      if (res.data.success) {
        toast.success("Success!");
      } else {
        toast.error("Error!");
      }
    });
    newBadges.push(id);
    setIsAddBadgeModal(false);
  };

  const handleShareLibrary = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("URL Copied");
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (
        ((num / 1000000).toFixed(1).replace(/\.0$/, "") * 1).toLocaleString() +
        "M"
      );
    } else if (num >= 1000) {
      return (
        ((num / 1000).toFixed(1).replace(/\.0$/, "") * 1).toLocaleString() + "K"
      );
    } else {
      return num.toLocaleString();
    }
  };

  const getSubMenus = (menu) => {
    return (
      Object.keys(categories).find((cat) =>
        categories[cat].some(
          (category) =>
            category.sub_id === menu.sub_id &&
            category.main_id === menu.main_id,
        ),
      ) || "Just For Fun"
    );
  };
  return (
    <div className="aibot-page 10">
      <Toaster />
      {loading ? <PageLoader /> : null}
      <div className="container mx-auto sm:px-0 px-5 py-10">
        {isPackage ? null : (
          <div className="breadcrumbs">
            <a onClick={() => navigate(-1)}>Library</a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#abadc6"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
            <p>{aibotInfo?.label}</p>
          </div>
        )}

        <div className="aibot-details">
          <div className="details">
            <div className="details-info">
              <Badge text={getSubMenus(aibotInfo)} size="md" />
              <h2>{aibotInfo?.label}</h2>
              <div className="aibot">
                <div className="aibot-owner">
                  <img
                    src={aibotInfo?.profile_picture || defaultUserUrl}
                    alt="owner-img"
                  />
                  <span>{aibotInfo?.username}</span>
                </div>

                <div className="flex flex-col md:hidden gap-4 my-4">
                  {isPackage ? (
                    <button
                      className="add-btn bg-[#12062E] text-white w-full"
                      onClick={() => window.history.back()}
                    >
                      Return to package
                    </button>
                  ) : (
                    <>
                      <a
                        className="add-btn bg-[#c1ff72] w-full"
                        href={`${import.meta.env.VITE_SERVER_URL}/login?library=${
                          libraryDetails?.id
                        }`}
                      >
                        Start Using This AI
                      </a>
                      <button
                        className="add-btn bg-[#12062E] text-white w-full"
                        onClick={() => handleShareLibrary(true)}
                      >
                        Share this AI
                      </button>
                    </>
                  )}
                </div>

                <MarkdownRenderer text={aibotInfo?.description} />

                {/*
                <p style={{ textAlign: "justify" }}>
                  {aibotInfo?.description
                    ? aibotInfo.description.split("\n").map((item, index) => (
                        <p key={index}>
                          {item}
                          <br />
                        </p>
                      ))
                    : ""}
                </p>   *
                   */}

                {
                  !isPackage &&
                  <span className="aibot-bookmark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#000000"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                    />
                  </svg>
                    <span>{formatNumber(String(aibotInfo?.downloads))}</span>
                  </span>
                }
              </div>
            </div>

            <div className="hidden md:flex gap-8 my-8">
              {isPackage ? (
                <button
                  className="add-btn bg-[#12062E] text-white w-[200px]"
                  onClick={() => window.history.back()}
                >
                  Return to package
                </button>
              ) : (
                <>
                  <a
                    className="add-btn bg-[#c1ff72] w-[200px]"
                    href={`${import.meta.env.VITE_SERVER_URL}/login?library=${
                      libraryDetails?.id
                    }`}
                  >
                    Start Using This AI
                  </a>
                  <button
                    className="add-btn bg-[#12062E] text-white w-[200px]"
                    onClick={() => handleShareLibrary(true)}
                  >
                    Share this AI
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="preview">
            <img src={aibotInfo?.lib_image || aibotInfo?.chat_logo?.url || defaultLogoUrl} alt="aibot-img" />

            <div className="hidden">
              <div className="add-achieve">
                <PlusIcon className="w-10 h-10" />
              </div>
            </div>
          </div>
        </div>

        <div className="aibot-tabs">
          <Tabs value={activeTab}>
            <TabsHeader
              className="aibot-tabs__header"
              indicatorProps={{
                className: "aibot-tabs__active",
              }}
            >
              <Tab
                value="description"
                onClick={() => setActiveTab("description")}
              >
                <div
                  className={
                    "aibot-tabs__label " +
                    (activeTab === "description" ? "active" : "")
                  }
                >
                  {React.createElement(DocumentTextIcon, {
                    className: "w-5 h-5",
                  })}
                  Builder Bio
                </div>
              </Tab>
              <Tab value="reviews" onClick={() => setActiveTab("reviews")}>
                <div
                  className={
                    "aibot-tabs__label " +
                    (activeTab === "reviews" ? "active" : "")
                  }
                >
                  {React.createElement(StarIcon, { className: "w-5 h-5" })}
                  Reviews
                </div>
              </Tab>
            </TabsHeader>
            <TabsBody className="aibot-tabs__body">
              <TabPanel value="description">
                <div className="aibot-tabs__body-content">
                  <AiDescriptionDetails
                    bio={aibotInfo?.author_details?.tell_us}
                  />
                </div>
              </TabPanel>
              <TabPanel value="reviews">
                <div className="aibot-tabs__body-content">
                  <AiReviewDetails
                    aibot={aibotInfo}
                    reviews={reviews}
                    action={() => {
                      getAllReviews(aibotInfo);
                    }}
                  />
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </div>

        <RelatedAiList list={chats} />
      </div>

      <Dialog
        open={false}
        handler={() => setIsAddBadgeModal(false)}
        className="rounded-md shadow-lg"
      >
        <DialogHeader>Add Badge</DialogHeader>
        <DialogBody divider>
          <div className="flex flex-wrap gap-3">
            {Badges.filter((_, id) => id < 9).map((item, id) => (
              <Tooltip
                content={<span className=" text-wrap">{ToolTips[id]}</span>}
                className="bg-white text-black shadow-lg z-[99999]"
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0, y: 25 },
                }}
                key={id}
              >
                <img
                  src={item}
                  className="w-16 h-16 hover:cursor-pointer"
                  onClick={() => handleAddBadge(id)}
                />
              </Tooltip>
            ))}
          </div>
        </DialogBody>
        <DialogFooter className="flex items-center justify-end gap-4 pb-8"></DialogFooter>
      </Dialog>

      <Dialog
        open={isAddLibraryModal}
        handler={() => setIsAddLibraryModal(false)}
        className="rounded-md shadow-lg"
      >
        <DialogBody divider>
          <label className="text-sitePrimary">Input your email</label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="rouned-md border border-sitePrimary p-2 w-full text-sitePrimary"
          />
        </DialogBody>
        <DialogFooter className="flex items-center justify-end gap-4 pb-8">
          <Button
            variant="outlined"
            className="text-sitePrimary normal-case"
            onClick={() => setIsAddLibraryModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="normal-case text-white bg-sitePrimary"
            onClick={() => handleAddLibrary()}
          >
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default AIBotPage;
