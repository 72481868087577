import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getPage } from "@/common/api/contentful.js";
import ContentfulRenderer from "@/components/ContentfulRenderer";
import PopularAIBot from "@/common/components/PopularAIBot/index.jsx";
import useGetLibrary, { CATEGORY_IDS } from "@/common/hooks/useGetLibrary";
import HowTaiskWorks from "@/common/components/sections/HowTaiskWorks";
import {ColumnContent } from "@/pages/ForYouPage/ForYouPage.jsx"

const MarketplaceContext = React.createContext();

export const MarketplaceProvider = ({ children }) => {
  const [pageContent, setPageContent] = useState({});
  const category = "marketplace";
  const getList = useGetLibrary({ category });

  const fetchPageContent = useCallback(async (category) => {
    const res = await getPage(category);
    setPageContent(res);
  }, []);

  // const subnav = SUBNAV_CATEGORY[category];
  // const subNavData = useMemo(() => {
  //   return categories.filter((x) => x.name === subnav)[0].roles.splice(0, 5);
  // }, [category]);

  useEffect(() => {
    fetchPageContent(category);
  }, [category]);

  return (
    <MarketplaceContext.Provider
      value={{
        category,
        pageContent,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
};

const Marketplace = () => {
  const { pageContent = {} } = useContext(MarketplaceContext);

  if (!pageContent.slug) {
    return <></>;
  }

  const HeroBanner = (
    <div
      className={`w-full h-[250px] relative flex justify-center items-center`}
      style={{
        background: `url(${pageContent.heroBanner})`,
        backgroundSize: "cover",
      }}
    >
      <h1
        style={{ textShadow: "0 0 2px black" }}
        className="text-6xl text-white absolute"
      >
        {pageContent.heroTitle}
      </h1>
    </div>
  );

  return (
    <div className="min-h-10 mt-32">
      {HeroBanner}
      <PopularAIBot />
      <main className="p-10 w-full flex justify-center ">
        <div className="w-full sm:w-[540px] md:w-[720px] lg:w-[1140px] max-w-[1320px]">
          <ColumnContent
            left={pageContent.topLeftColumn}
            right={pageContent.topRightColumn}
            showStartedOnLeft
          />
          <ColumnContent
            left={pageContent.middleLeftColumn}
            right={pageContent.middleRightColumn}
          />
          <ColumnContent
            left={pageContent.bottomLeftColumn}
            right={pageContent.bottomRightColumn}
            showStartedOnLeft
          />
        </div>
      </main>
    </div>
  );
};


const MarketplaceContainer = () => {
  return (
    <MarketplaceProvider>
      <Marketplace />
      <HowTaiskWorks />
    </MarketplaceProvider>
  );
};

export default MarketplaceContainer;
