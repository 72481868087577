import { ThreeDots } from "react-loader-spinner";
import cx from "classnames";
import "./styles.scss";

const PageLoader = (props) => {
  const { fullPage = false, styles } = props;

  return (
    <div
      className={cx("app-page-loader", { "full-page": fullPage })}
      style={styles}
    >
      <ThreeDots
        visible={true}
        height="20"
        width="60"
        color="#12062E"
        radius="9"
        ariaLabel="three-dots-loading"
      />
      <ThreeDots
        visible={true}
        height="20"
        width="60"
        color="#12062E"
        radius="9"
        ariaLabel="three-dots-loading"
      />
      <ThreeDots
        visible={true}
        height="20"
        width="60"
        color="#12062E"
        radius="9"
        ariaLabel="three-dots-loading"
      />
    </div>
  );
};

export default PageLoader;
