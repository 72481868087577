import { Typography } from "@material-tailwind/react";
import "./styles.scss";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import taiskLogoWhite from "../../../assets/logo/taisk-white.png";
import icoLogo from "../../../assets/logo/ico-logo.png";
import cyberEssentialsLogo from "../../../assets/logo/cyber-essentials.svg";
import googlePlay from "../../../assets/logo/google-play.png";
import appleStore from "../../../assets/logo/apple-store.png";
import { useState } from "react";
import { subscribeToMarketplace } from "../../api/marketplace";
import { EXTRA_URLs } from "../../../utils/constants";

const LINKS = [
  {
    title: "LEGAL",
    items: [
      {
        label: "Privacy Policy",
        path: "/privacy-policy",
      },
      {
        label: "Cookie Policy",
        path: "/cookie-policy",
      },
      {
        label: "Anti-Slavery Policy",
        path: "/anti-slavery-policy",
      },
      {
        label: "EU AI Act",
        path: "/eu-ai-act",
      },
    ],
  },
  {
    title: "",
    items: [
      {
        label: "Data Security Policy",
        path: "/data-security-policy",
      },
      {
        label: "Data Protection",
        path: "/data-protection-agreememt",
      },
      {
        label: "User Terms and Conditions",
        path: "/user-terms-and-conditions",
      },
    ],
  },
  {
    title: "SOLUTION",
    items: [
      {
        label: "Custom AI Development",
        path: EXTRA_URLs.CUSTOM_AI_DEVELOPMENT,
        target: "_blank",
      },
      {
        label: "Support",
        path: EXTRA_URLs.SUPPORT,
        target: "_blank",
      },
      {
        label: "Data Security",
        path: EXTRA_URLs.DATA_SECURITY,
        target: "_blank",
      },
      {
        label: "Affiliate",
        path: "/affiliate",
      },
      {
        label: "About Us",
        path: "/about-us",
      },
    ],
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState("");
  const [doneSubscribe, setDoneSubscribe] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);

  const checkEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const handleSubscribe = () => {
    if (!email) return;
    setDoneSubscribe("");
    const validEmail = checkEmail(email);
    setHasError(validEmail ? "" : "Please provide valid email address");
    if (validEmail) {
      setIsSubscribing(true);
      subscribeToMarketplace({ email })
        .then((res) => {
          if (res.status && res.status !== 200) {
            setHasError(res?.data?.message);
            return;
          }
          setEmail("");
          setDoneSubscribe(res?.message);
        })
        .finally(() => {
          setIsSubscribing(false);
        });
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") handleSubscribe();
  };

  return (
    <footer className="footer-wrapper relative w-full">
      <div className="footer-email-section sm:px-0 px-5">
        <div className="email-section container mx-auto sm:px-0 px-5">
          <h2>Let us keep you updated</h2>
          <p>Join our monthly newsletter for all things TAISK:</p>
          <div className="email-form">
            <div className="email-input">
              <input
                type="email"
                placeholder="Email your email"
                className="email-input-field"
                value={email}
                onChange={(e) => {
                  setHasError("");
                  setDoneSubscribe("");
                  setEmail(e.target.value);
                }}
                onKeyDown={handleOnKeyDown}
              />
              <button disabled={isSubscribing} onClick={handleSubscribe}>
                Subscribe
              </button>
            </div>
            {hasError && <span className="error">{hasError}</span>}
            {doneSubscribe && (
              <span className="text-green-800">{doneSubscribe}</span>
            )}
            <p>
              We care about your data in our{" "}
              <a href="/privacy-policy" target="_blank">
                privacy policy
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer-sitemap-container w-full">
        <div className="container mx-auto sm:px-0 px-5">
          <div className="footer-sitemap">
            <div className="footer-logos">
              <img src={taiskLogoWhite} alt="main-logo" className="main-logo" />
              <div className="flex gap-2 mb-2 md:w-[124px] w-fit justify-between">
                <a
                  href="https://www.linkedin.com/company/taisk/"
                  target="_blank"
                  title="LinkedIn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    viewBox="0 0 30 30"
                    fill="#ABADC6"
                  >
                    <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
                  </svg>
                </a>
                <a
                  href="https://youtube.com/@taiskai?si=0wszV22Q0F5VAHS-"
                  target="_blank"
                  title="YouTube"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="#ABADC6"
                  >
                    <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z"></path>
                  </svg>
                </a>
              </div>
              <div className="footer-sitemap ">
                <div className="footer-logos">
                  <img src={icoLogo} alt="ico-logo" className="icon-logo" />
                  <img
                    src={cyberEssentialsLogo}
                    alt="cyber essentials logo"
                    className="icon-logo"
                  />
                </div>
              </div>
            </div>
            <div className="footer-sitemap-list">
              {LINKS.map(({ title, items }, idx) => (
                <ul key={idx} className="footer-sitemap-item">
                  <Typography variant="small" color="blue-gray">
                    {title}
                  </Typography>
                  {items.map((link, idx) => (
                    <li key={link + idx}>
                      <Typography
                        as="a"
                        href={link?.path}
                        color="gray"
                        target={link?.target || ""}
                      >
                        {link?.label}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className="footer-sitemap">
            <div className="footer-logos"></div>
            <div className="footer-sitemap-list">
              <img src={taiskLogoWhite} alt="main-logo" className="main-logo" />
              <Typography variant="small" className="footer-text-copyright">
                All rights reserved © 2024 TAISK, company number 811140.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
