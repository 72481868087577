import { Navigate, useRoutes } from "react-router-dom";
import Layout from "../common/components/Layout";
import HomePage from "../pages/HomePage";
import AIBotPage from "../pages/AiBotPage";
import Library from "../pages/Library";
import CreatorPackage from "../pages/Library/CreatorPage";
import PrivacyPolicyPage from "../pages/Policy/PrivacyPolicyPage";
import CookiePolicyPage from "../pages/Policy/CookiePolicyPage";
import AntiSlaveryPolicy from "../pages/Policy/AntiSlaveryPolicy";
import DataSecurityPolicy from "../pages/Policy/DataSecurityPolicy";
import DataProtectionAgreement from "../pages/Policy/DataProtectionAgreement";
import TermsAndConditionPage from "../pages/Policy/TermsAndConditionPage";
import SearchPage from "../pages/SearchPage";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import Affiliate from "../pages/Affiliate/Affiliate";
import ContactUsPage from "../pages/ContactUsPage/ContactUsPage";
import Marketplace from "@/pages/Marketplace/Marketplace";
import ForYouPage from "@/pages/ForYouPage/ForYouPage";
import PluginPage from "@/pages/ForYouPage/Plugins";
import CreatorsPage from "@/pages/ForYouPage/CreatorsPage";
import EUAiAct from "../pages/Policy/EUAiAct"

import ContentfulPage from "@/pages/ContentfulPage/ContentfulPage";
import BookADemo from "@/pages/IFrames/BookDemo";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/library/:category/",
        element: <Library />,
      },
      {
        path: "/library/:category/:subCategory",
        element: <Library />,
      },
      {
        path: "/search/library/:search",
        element: <SearchPage />,
      },
      {
        path: "/aibot/id/:id",
        element: <AIBotPage />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/cookie-policy",
        element: <CookiePolicyPage />,
      },
      {
        path: "/anti-slavery-policy",
        element: <AntiSlaveryPolicy />,
      },
      {
        path: "/eu-ai-act",
        element: <EUAiAct />,
      },
      {
        path: "/data-security-policy",
        element: <DataSecurityPolicy />,
      },
      {
        path: "/data-protection-agreememt",
        element: <DataProtectionAgreement />,
      },
      {
        path: "/user-terms-and-conditions",
        element: <TermsAndConditionPage />,
      },
      {
        path: "/about-us",
        element: <AboutUsPage />,
      },
      {
        path: "/affiliate",
        element: <Affiliate />,
      },
      {
        path: "/talk-with-us",
        element: <ContactUsPage />,
      },
      {
        path: "/marketplace",
        element: <Marketplace />,
      },
      {
        path: "/for/:category",
        element: <ForYouPage />,
      },
      {
        path: "/creators",
        element: <CreatorsPage />,
      },
      {
        path: "/creator/:userId/:packageId",
        element: <CreatorPackage />,
      },
      {
        path: "/creator/:slug",
        element: <CreatorPackage />,
      },
      {
        path: "/plugin/:plugin",
        element: <PluginPage />,
      },
      {
        path: "/book-a-demo",
        element: <BookADemo />,
      },
      {
        path: "/:url",
        element: <ContentfulPage />,
      },
    ],
  },
];

const AppRoutes = () => {
  return useRoutes(routes);
};

export default AppRoutes;
