import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { getPageContent } from "../../common/api/contentful";
import ComponentRenderer from "./ComponentsRender";

const ContentfulPage = (props) => {
  const { url } = useParams();

  const options = {};

  const { data, loading } = useQuery({
    queryKey: [url],
    queryFn: () => getPageContent(url),
    ...options,
  });
  // console.log(data);

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="mt-8 lg:mt-32">
      {data?.body.map((x) => (
        <ComponentRenderer {...x} />
      ))}
    </div>
  );
};

export default ContentfulPage;
