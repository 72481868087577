import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import Image from "../../Image";
import { categories } from "../../../utils/category";
import { useNavigate, useParams } from "react-router-dom";

const SubNavMenu = (props) => {
  const { category, name, roles, path, disabled } = props;

  const navigate = useNavigate();
  const params = useParams();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  const handleIcon = (subCategory) => {
    const { roles } = categories?.find((_i) => _i.name === category);
    const { icon } = roles?.find((_i) => _i.name === subCategory) || {};
    return icon ?? "";
  };

  const handleNavigate = () => {
    if (path?.includes("for")) {
      navigate(path);
      return;
    }
    navigate(`/library/${category}`);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsMenuOpen(false),
    );
    window.addEventListener("scroll", () => {
      if (Boolean(window.scrollY <= 20)) setIsMenuOpen(false);
    });
  }, []);

  const renderItems = roles?.map(({ name, sub_id, icon }, key) => {
    return (
      <a
        onClick={() => {
          navigate(`/library/${category}/${sub_id}`);
        }}
        key={key}
      >
        <MenuItem className="flex items-center gap-3 rounded-sm">
          <div className="flex items-center justify-center p-1">
            <Image imgUrl={icon} className="h-5 w-5" />
          </div>
          <div>
            <Typography as="span" variant="small" className="font-normal">
              {name}
            </Typography>
          </div>
        </MenuItem>
      </a>
    );
  });

  const Design1 = (
    <Button
      variant="text"
      className={
        `w-full justify-center nav-menu-item bg-sitePrimary text-white hover:bg-secondary hover:text-sitePrimary flex items-center rounded-md gap-2 px-4 normal-case py-2 ` +
        (isMenuOpen || path.split("/")[2] === params?.category ? "active" : "")
      }
      disabled={disabled}
      onClick={handleNavigate}
    >
      <Typography as="li" variant="small" className="p-1 font-normal">
        {name || category}
      </Typography>
    </Button>
  );

  const Design2 = (
    <Button
      variant="text"
      className={
        `w-full justify-center border-secondary border-2 hover:bg-sitePrimary hover:text-white hover:border-sitePrimary  flex items-center rounded-md gap-2 px-4 normal-case py-2 ` +
        (isMenuOpen || path.split("/")[2] === params?.category
          ? "active bg-sitePrimary text-white border-sitePrimary"
          : "")
      }
      onClick={handleNavigate}
    >
      <Typography as="li" variant="small" className="p-1 font-normal">
        {name || category}
      </Typography>
    </Button>
  );

  const Design3 = (
    <Button
      variant="text"
      className={
        `w-full justify-center nav-menu-item flex items-center rounded-none gap-2 px-0 normal-case py-2 ` +
        (isMenuOpen || path.split("/")[2] === params?.category ? "active" : "")
      }
      onClick={handleNavigate}
    >
      <Typography as="li" variant="small" className="p-1 font-normal">
        {name || category}
      </Typography>
    </Button>
  );

  const designs = [Design2];

  return (
    <Menu
      open={isMenuOpen}
      handler={setIsMenuOpen}
      allowHover
      offset={{ mainAxis: 1 }}
      placement="bottom"
    >
      <MenuHandler>
        {/* {props.showDefault ? Design3 : designs?.[props.idx] || designs[0]} // comment since we dont need the default for now */}
        <div className="w-full">
          {props.showDefault ? Design3 : designs?.[props.idx] || designs[0]}
        </div>
      </MenuHandler>
      {roles?.length ? (
        <MenuList className="hidden max-w-screen-xl rounded-none lg:block max-h-[300px]">
          <ul className="grid grid-cols-2 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      ) : (
        <MenuList className="hidden h-0 p-0 max-w-screen-xl rounded-none lg:block"></MenuList>
      )}
    </Menu>
  );
};

export default SubNavMenu;
