import { SET_AIBOT_LIST } from "../type";

const initialState = {
  aibots: []
};

const libraryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AIBOT_LIST:
      return {
        ...state,
        aibots: payload
      };
    default:
      return state;
  }
};

export default libraryReducer;
