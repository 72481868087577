import React, { useMemo, useState } from "react";
import { Typography, Button, Collapse } from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import "../styles.scss";
import { useNavigate } from "react-router-dom";
import { EXTRA_URLs } from "../../../../utils/constants";
import { categories } from "../../../../common/utils/category";

const MobileNavigation = (props) => {
  const { list, open = false } = props;

  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState({});
  const [openNavCategory, setOpenNavCategory] = useState({
    Education: true,
    Entrepreneurship: true,
    Personal: true,
  });

  const navList = useMemo(() => {
    if (!list?.length) return [];
    return list?.map((_i, idx) => ({
      ..._i,
      id: idx + 1,
    }));
  }, [list]);

  const handleOpen = (id) => {
    setOpenNav((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleMenu = (type, menuItem) => {
    props?.navigate(menuItem);
    props?.setOpenNav(false);
  };

  const handleOpenCategory = (category) => {
    setOpenNavCategory((prevState) => ({ ...prevState, [category]: !prevState[category] }));
  };

  const handleGetStarted = () => {
    window.location.href = EXTRA_URLs.GET_STARTED_PATH;
  };

  const handleLogin = () => {
    window.location.href = EXTRA_URLs.LOGIN_PATH;
  };

  const handleSubCategories = (category) => {
    return categories.find((cat) => cat.name === category).roles;
  }

  const handleMenuSubCategories = (category, subId) => {
    navigate(`/library/${category}/${subId}`);
    props?.setOpenNav(false);
  };

  return (
    <>
      <Collapse open={open} className="collapse-navbar-container">
        <div className="collapse-navbar">
          <div className="flex flex-col gap-1 lg:mb-0 lg:mt-0 mb-4">
            <Button
              onClick={handleLogin}
              fullWidth
              variant="text"
              size="sm"
              className="normal-case"
            >
              <span>Login</span>
            </Button>
            <Button
              fullWidth
              variant="gradient"
              size="sm"
              className="normal-case get-start-btn"
              onClick={handleGetStarted}
            >
              <span>Get Started</span>
            </Button>
          </div>
          <ul className="flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-6">
            {navList?.map((_nav, idx) => (
              <Typography
                key={idx}
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
              >
                <button
                  onClick={() => handleOpen(_nav.id)}
                  className="flex items-center my-1"
                >
                  {_nav?.title}
                  <ChevronDownIcon
                    strokeWidth={2.5}
                    className={`block h-5 w-5 transition-transform lg:hidden ${
                      !openNav[_nav.id] ? "" : "rotate-180"
                    }`}
                  />
                </button>
                <Collapse open={!openNav[_nav.id]}>
                  <ul className="flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
                    {_nav?.navList?.map((_item, _idx) => (
                      <Typography
                        key={_idx}
                        as="li"
                        variant="small"
                        color="blue-gray"
                        className="py-1.5 font-normal"
                      >
                        <div className="flex items-center justify-between">
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              handleMenu(_nav?.title, _item);
                            }}
                            className="flex items-center"
                          >
                            {_item?.name}
                          </a>
                          {_nav.title === "Categories" && (
                            <button
                              onClick={() => handleOpenCategory(_item.category)}
                            >
                              <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-5 w-5 transition-transform lg:hidden ${
                                  !openNavCategory[_item.category] ? "" : "rotate-180"
                                }`}
                              />
                            </button>
                          )}
                        </div>
                        {_nav.title === "Categories" && (
                          <Collapse open={!openNavCategory[_item.category]}>
                            <ul className="flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
                              {handleSubCategories(_item.category)?.map((_itemSubCat, _idx) => (
                                <Typography
                                  key={_idx}
                                  as="li"
                                  variant="small"
                                  color="blue-gray"
                                  className="py-1.5 font-normal"
                                >
                                  <a
                                    onClick={() => {
                                      handleMenuSubCategories(_item.category, _itemSubCat.sub_id);
                                    }}
                                    className="flex items-center pl-3"
                                  >
                                    {_itemSubCat?.name}
                                  </a>
                                </Typography>
                              ))}
                            </ul>
                          </Collapse>
                        )}
                      </Typography>
                    ))}
                  </ul>
                </Collapse>
              </Typography>
            ))}
          </ul>
        </div>
      </Collapse>
    </>
  );
};

export default MobileNavigation;
