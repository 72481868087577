import { SET_CATEGORIES_MENU, SET_TESTIMONIALS } from "../type";

const initialState = {
  testimonials: []
};

const marketplaceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TESTIMONIALS:
      return {
        ...state,
        testimonials: payload
      };
    case SET_CATEGORIES_MENU:
      return {
        ...state,
        categories: payload
      };
    default:
      return state;
  }
};

export default marketplaceReducer;
