import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import "./styles.scss";
import CheckIcon from "./CheckIcon";
import { EXTRA_URLs } from "../../../../utils/constants";

const Subscription = ({ innerRef }) => {
  const staterPlans = [
    "1 Licence to TAISK",
    "Create and Use Unlimited AI ChatBots",
    "1000 Monthly Credits (top-ups available)",
    "Premium Access to the Best LLMs from GROQ, Meta, OpenAI, Google, Anthropic and Perplexity",
    "Train Your AI ChatBots With Your Own Documents, Data and URLs",
    "Range of Tools Including Speech, Image and Data Analysis, Image Generation and Lead Capture",
    "Huge Customisation Options",
    "Embed as a Chat Bubble or Iframe Anywhere",
    "Access to AI Library",
    "Sell Your AI With A Unique Creator Page",
  ];

  const growthPlans = [
    "1 Licence to TAISK",
    "All AI Chatbot Features",
    "+",
    "Unlimited AI Bots and AI Agents",
    "10,000 Monthly Credits (Top-Ups Available)",
    "Build Powerful Automations With: Gmail, Google Calendar, Google Sheets, Google Docs, LinkedIn, Hubspot, Wonde, TaiskDATA, Outlook, and many more", 
    "Sell Your AI With A Unique Creator Page",
  ];

  const enterprisePlans = [
    "Unlimited Licences to TAISK",
    "All LLMs, Tools and Plugins",
    "Build Chatbots and Agents For Your Organisation",
    "+",
    "100,000 Monthly Credits (top-ups available)",
    "API Access To Connect Your Software",
    "Shared Workplace for Teams To Collaborate",
    "Enterprise Grade Data Security",
    "EU AI Act Compliant",
    "Priority Customer Support & Training",
    "Sell Your AI With A Unique Creator Page",
  ];

  const customPlans = [
    "1 Licence to TAISK",
    "250 Monthly Credits (top-ups available)",
    "Build Custom AI Chatbots and AI Agents",
    "Full Access to all TAISK Features Including the Latest Large Language Models, Tools and Plugins",
    "Limited Customisation",
    "Discover What AI Can Do For Your Tasks For Free",
  ];

  const goToSubscription = () => {
    window.open(EXTRA_URLs.GET_STARTED_PATH, "_blank");
  };

  const goToTaiskLabs = () => {
    window.open(EXTRA_URLs.TAISK_LABS, "_blank");
  };

  return (
    <div className="subscription-section" ref={innerRef}>
      <div className="container mx-auto sm:px-0 px-5">
        <h2>Subscriptions</h2>
        <h3>We have four pricing options</h3>
        <div className="subscription-options gap-4">
          <Card className="subscription-options__item">
            <CardBody className="subscrition-content">
              <div className="subscrition-content__header">
                <h2>Free Subscription</h2>
                <div className="price">
                  <div className="amount">£0.00</div>
                  <div className="duration">/month</div>
                </div>
              </div>
              <div className="subscrition-content__details">
                <p className="font-bold mb-4">Explore TAISK For Free, No Credit Card Required</p>
                {customPlans?.map((_plan, idx) => (
                  <div className="sub-item" key={idx}>
                    <p>{_plan}</p>
                    <div className="sub-item__icon">
                      <CheckIcon className={"h-4 w-4"} fill={"#C1FF72"} />
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
            <CardFooter className="subscription-actions pt-0">
              <button onClick={goToSubscription} style={{ backgroundColor: "#C1FF72" }}>
                <span style={{ color: "#12062e" }}>Get Started For Free</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#12062e"
                  className="w-3 h-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <p>No hidden fees or contracts.</p>
            </CardFooter>
          </Card>

          <Card className="subscription-options__item">
            <CardBody className="subscrition-content">
              <div className="subscrition-content__header">
                <h2>AI Chatbots</h2>
                <div className="price">
                  <div className="amount">£8.99</div>
                  <div className="duration">/month*</div>
                </div>
              </div>
              <div className="subscrition-content__details">
                <p className="font-bold mb-4">
                  Build Custom AI Bots With Any LLM and Your Data
                </p>
                {staterPlans?.map((_plan, idx) => (
                  <div className="sub-item" key={idx}>
                    <p>{_plan}</p>
                    <div className="sub-item__icon">
                      <CheckIcon className={"h-4 w-4"} fill={"#C1FF72"} />
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
            <CardFooter className="subscription-actions pt-0">
              <button onClick={goToSubscription}>
                <span>Start Free Trial</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#ffffff"
                  className="w-3 h-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <p>No hidden fees or contracts.</p>
            </CardFooter>
          </Card>
          <Card className="subscription-options__item">
            <CardBody className="subscrition-content update">
              <div className="subscrition-content__header">
                <h2>AI Agents</h2>
                <div className="price">
                  <div className="amount">
                    <div className="amount-update"></div>
                    £89.99
                  </div>
                  <div className="duration">/month*</div>
                </div>
                <div className="price-update">£48.99 early access discount</div>
              </div>
              <div className="subscrition-content__details">
                <p className="font-bold mb-4">
                  Build AI Agents To Help You With Any Task
                </p>
                {growthPlans?.map((_plan, idx) => (
                  <div className="sub-item" key={idx}>
                    <p>{_plan}</p>
                    <div className="sub-item__icon">
                      <CheckIcon className={"h-4 w-4"} fill={"#C1FF72"} />
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
            <CardFooter className="subscription-actions pt-0">
              <button onClick={goToSubscription}>
                <span>Start Free Trial</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#ffffff"
                  className="w-3 h-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <p>No hidden fees or contracts.</p>
            </CardFooter>
          </Card>
          <Card className="subscription-options__item">
            <CardBody className="subscrition-content">
              <div className="subscrition-content__header">
                <h2>AI For Teams</h2>
                <div className="price">
                  <div className="amount">£469</div>
                  <div className="duration">/month*</div>
                </div>
              </div>
              <div className="subscrition-content__details">
                <p className="font-bold mb-4">
                  AI Solutions For Organisations To Build, Use and Share AI
                </p>
                {enterprisePlans?.map((_plan, idx) => (
                  <div className="sub-item" key={idx}>
                    <p>{_plan}</p>
                    <div className="sub-item__icon">
                      <CheckIcon className={"h-4 w-4"} fill={"#C1FF72"} />
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
            <CardFooter className="subscription-actions pt-0">
              <button onClick={goToSubscription}>
                <span>Start Free Trial</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#ffffff"
                  className="w-3 h-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <p>No hidden fees or contracts.</p>
            </CardFooter>
          </Card>
        </div>
        <p className="mt-8">
          *Subscriptions to TAISK are subject to VAT and our
          <a href="/user-terms-and-conditions" style={{ color: "blue" }}>
            {" "}
            Terms and Conditions
          </a>
          . Please ensure you read and understand them fully before subscribing.
        </p>
      </div>
    </div>
  );
};

export default Subscription;
