import { EXTRA_URLs } from "../../../../utils/constants";
import CheckIcon from "../Subscription/CheckIcon";
import MarkdownRenderer from "@/components/MarkdownRenderer";
import "./styles.scss";

const PreviewDetails = (props) => {
  const {
    title,
    description,
    list,
    imgUrl = "",
    imagePosition = "left",
    hideButton = false,
    buttonText = "",
  } = props;

  const handleGetStarted = () => {
    props.handleGetStarted
      ? props.handleGetStarted()
      : (window.location.href = EXTRA_URLs.GET_STARTED_PATH);
  };

  return (
    <div className="preview-details-section">
      <div className={"flex container mx-auto sm:px-0 px-5 " + imagePosition}>
        <div className="flex-1 preview-image">
          <img src={imgUrl} alt={title} />
        </div>
        <div
          className={
            "flex-1 details flex p-8 flex-col justify-between h-auto " +
            imagePosition
          }
        >
          <div className="flex-1">
            <h2>{title}</h2>
            <div>
              <MarkdownRenderer text={description} />
            </div>
          </div>

          <div className="list">
            {list?.map((item, idx) => (
              <div className="item mt-4" key={idx}>
                <CheckIcon className={"h-4 w-4 mb-4"} fill={"#C1FF72"} />
                <span className="mb-4">{item}</span>
              </div>
            ))}
          </div>

          {!hideButton && !props.purchaseButton && (
            <div className="actions">
              <button className="get-started" onClick={handleGetStarted}>
                {buttonText || "Get started for free "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#fff"
                  className="w-3 h-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
              {/* <button>Learn more</button> */}
            </div>
          )}

          {props.purchaseButton ? (
            <button className="text-2xl font-bold" onClick={handleGetStarted}>
              <div className="flex border border-sitePrimary rounded-md gap-2">
                <div className="p-2">{props.price}</div>
                <div className="p-2 px-4 text-white bg-sitePrimary rounded-md">
                  {props.buttonText}
                </div>
              </div>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PreviewDetails;
