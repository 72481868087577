import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AiLibrary from "../../common/components/AiLibrary";
import Headline from "../../common/components/Headline";
import PopularAIBot from "../../common/components/PopularAIBot";
import CreateOwnSection from "../../common/components/sections/CreateOwnSection";
import HowTaiskWorks from "../../common/components/sections/HowTaiskWorks";
import PreviewDetails from "../../common/components/sections/PreviewDetails";
import Startup from "../../common/components/sections/Startup";
import Subscription from "../../common/components/sections/Subscription";
import "./styles.scss";
import BuildImg from "../../assets/icons/build.svg";
import BotsImg from "../../assets/icons/bots.svg";
import EmbedImg from "../../assets/icons/embed.svg";
import { useLocation } from "react-router-dom";
import { setAIBotList } from "../../common/redux/actions/library";
import { getLibrary } from "../../common/api/library";

const HomePage = () => {
  const pricingRef = useRef(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const testimonials = useSelector((state) => state.marketplace.testimonials);

  const testimonialList = useMemo(() => {
    return testimonials;
  }, [testimonials]);

  const getList = () => {
    setIsLoading(true);
    const params = {
      page: 1,
      menu: null,
      perpage: 8,
      sortby: 0,
      subMenu: 0,
    };
    getLibrary(params)
      .then((res) => {
        if (res && res?.data) {
          setAIBotList(dispatch, res.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getList();
    if (location?.hash && pricingRef?.current) {
      pricingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [location]);

  return (
    <div className="home-page">
      <Headline testimonials={testimonialList} />
      <PopularAIBot />
      <PreviewDetails
        imgUrl={BuildImg}
        title="Get Started With TAISK For Free"
        description="Got a specific task you want AI to do? Simply, create your own AI Bots and Agents or explore and select one from our library."
        list={[
          "Start by building custom AI Bots with the best Large Language Models",
          "Customise with your information, set the behaviours and style your branding",
          "Build as many as you like, publish them on the library and sell them to your network",
        ]}
      />
      <PreviewDetails
        imgUrl={BotsImg}
        title="Automate Your Tasks With AI Agents"
        description="AI Bots are great for specific tasks, but if you chain them together or combine them with plugins you can assemble powerful, autonomous AI Agents."
        list={[
          "How about a marketing agent that automatically identifies trends and publishes posts to your social media",
          "Or Agents that answer customer enquiries on your website or via email, 24/7",
          "With TAISK it is easy. No code, just drag and drop your AI and plugins into place",
        ]}
        imagePosition="right"
      />
      <PreviewDetails
        imgUrl={EmbedImg}
        title="Want To Make Money From Your Own AI? Sell Them!"
        description="Build your own AI bots and agents, use them for your tasks, share them with your team and if you like, sell them on TAISK with your own unique creator page."
        list={[
          "Create and customise your own TAISK creator page, build a package of AI tools, set your price and promote it with your own URL",
          "100% of any sales of your AI go to you via STRIPE, so build your AI and get promoting to your network!",
          "Whitelabel your AI and be confident that your IP is protected",
        ]}
      />
      <AiLibrary isLoading={isLoading} showPagination={false} />
      <HowTaiskWorks />
      <div className="how-taisk-extensions"></div>
      <Subscription innerRef={pricingRef} />
    </div>
  );
};

export default HomePage;
