import { useState } from "react";
import { MapPinIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import "./styles.scss";
import { Button } from "@material-tailwind/react";
import toast, { Toaster } from "react-hot-toast";
import { emailToContactUs } from "../../common/api/marketplace";

const ContactUsPage = () => {
  const [contactUsForm, setContactUsForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organisation: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactUsForm(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};
    if (!contactUsForm.firstName.trim()) newErrors.firstName = "First name is required";
    if (!contactUsForm.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!contactUsForm.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(contactUsForm.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!contactUsForm.message.trim()) newErrors.message = "Message is required";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitContactUs = () => {
    if (!validateForm()) return;

    emailToContactUs({
      firstName: contactUsForm.firstName,
      lastName: contactUsForm.lastName,
      email: contactUsForm.email,
      organisation: contactUsForm.organisation || null, // Send null if empty
      message: contactUsForm.message,
    })
      .then((response) => {
        const status = response.status || (response.data && response.data.status);
        
        if (status === "success") {
          toast.success(
            "Thanks for submitting your message, we will be in touch soon",
            {
              duration: 3000,
              position: "top-center",
              style: {
                background: "#12062e",
                color: "#c1ff72",
                fontSize: "18px",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                marginTop: "3rem",
              },
              icon: "✅",
            }
          );
          // Clear the form after successful submission
          setContactUsForm({
            firstName: "",
            lastName: "",
            email: "",
            organisation: "",
            message: "",
          });
        } else {
          throw new Error('Submission failed');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error(
          "Something went wrong, please try again later",
          {
            duration: 3000,
            position: "top-center",
            style: {
              background: "#12062e",
              color: "#ff7272",
              fontSize: "18px",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              marginTop: "3rem",
            },
            icon: "❌",
          }
        );
      });
  };

  return (
    <div className="contact-us-page">
      <Toaster />
      <div className="container mx-auto sm:px-0 px-5 py-[3rem]">
        <h2>Let's Talk</h2>
        <div className="w-full flex md:flex-row flex-col gap-10 md:pt-10 pt-4 pb-[3rem] border-b-2 border-[#c1ff72]">
          <div className="w-full contact-us--info">
            <div className="flex items-start gap-6 md:mb-10 mb-4">
              <div className="min-w-[30px]">
                <MapPinIcon className="w-7 h-7 relative top-[2px]" />
              </div>
              <p>
                Summit House, 4-5 Mitchell Street, Edinburgh, United Kingdom,
                EH6 7BD
              </p>
            </div>
            <div className="flex items-start gap-6 pl-[2px]">
              <div className="min-w-[30px]">
                <EnvelopeIcon className="w-6 h-6" />
              </div>
              <a href="mailto:info@taisk.com">info@taisk.com</a>
            </div>
          </div>
          <div className="w-full contact-us--form">
            <form>
              <div className="w-full flex items-center gap-6 mb-6">
                <div className="flex flex-col w-full">
                  <span className="font-bold text-[#12062E] mb-1 text-[17px] lending-[22px]">
                    First Name *
                  </span>
                  <input
                    type="text"
                    name="firstName"
                    value={contactUsForm.firstName}
                    onChange={handleInputChange}
                    className={`block w-full px-4 py-2 mt-2 text-[--site-main-Login] bg-transparent border ${
                      errors.firstName ? 'border-red-500' : 'border-[--site-main-modal-input-border-color]'
                    } focus:outline-none focus:ring focus:ring-opacity-40`}
                  />
                  {errors.firstName && <span className="text-red-500 text-sm mt-1">{errors.firstName}</span>}
                </div>
                <div className="flex flex-col w-full">
                  <span className="font-bold text-[#12062E] mb-1 text-[17px] lending-[22px]">
                    Last Name *
                  </span>
                  <input
                    type="text"
                    name="lastName"
                    value={contactUsForm.lastName}
                    onChange={handleInputChange}
                    className={`block w-full px-4 py-2 mt-2 text-[--site-main-Login] bg-transparent border ${
                      errors.lastName ? 'border-red-500' : 'border-[--site-main-modal-input-border-color]'
                    } focus:outline-none focus:ring focus:ring-opacity-40`}
                  />
                  {errors.lastName && <span className="text-red-500 text-sm mt-1">{errors.lastName}</span>}
                </div>
              </div>
              <div className="flex flex-col w-full mb-6">
                <span className="font-bold text-[#12062E] mb-1 text-[17px] lending-[22px]">
                  Email *
                </span>
                <input
                  type="email"
                  name="email"
                  value={contactUsForm.email}
                  onChange={handleInputChange}
                  className={`block w-full px-4 py-2 mt-2 text-[--site-main-Login] bg-transparent border ${
                    errors.email ? 'border-red-500' : 'border-[--site-main-modal-input-border-color]'
                  } focus:outline-none focus:ring focus:ring-opacity-40`}
                />
                {errors.email && <span className="text-red-500 text-sm mt-1">{errors.email}</span>}
              </div>
              <div className="flex flex-col w-full mb-6">
                <span className="font-bold text-[#12062E] mb-1 text-[17px] lending-[22px]">
                  Organisation/School Name
                </span>
                <input
                  type="text"
                  name="organisation"
                  value={contactUsForm.organisation}
                  onChange={handleInputChange}
                  className="block w-full px-4 py-2 mt-2 text-[--site-main-Login] bg-transparent border border-[--site-main-modal-input-border-color] focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className="flex flex-col w-full mb-10">
                <span className="font-bold text-[#12062E] mb-1 text-[17px] lending-[22px]">
                  Message *
                </span>
                <textarea
                  name="message"
                  value={contactUsForm.message}
                  onChange={handleInputChange}
                  rows={5}
                  className={`block w-full px-4 py-2 mt-2 text-[--site-main-Login] bg-transparent border ${
                    errors.message ? 'border-red-500' : 'border-[--site-main-modal-input-border-color]'
                  } focus:outline-none focus:ring focus:ring-opacity-40`}
                />
                {errors.message && <span className="text-red-500 text-sm mt-1">{errors.message}</span>}
              </div>
              <div className="flex justify-end w-full mb-4">
                <Button
                  onClick={handleSubmitContactUs}
                  className="normal-case bg-white border border-[--site-onboarding-primary-color] py-1 px-6 rounded-md flex items-center justify-center gap-2 text-black text-[22px] lending-[28px] font-bold"
                >
                  Send
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;