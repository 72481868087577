import "../styles.scss";
import AiBot from "../../AiBot";
import { useMemo } from "react";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const List = (props) => {
  const { currentPage, pageCount, pageChange, hidePagination } = props;

  const handlePage = ({ nextSelectedPage }) => {
    if (!nextSelectedPage && nextSelectedPage !== 0) return;
    pageChange(nextSelectedPage + 1);
  };

  const aiList = useMemo(() => {
    return props?.list?.length ? props?.list : [];
  }, [props?.list]);

  return (
    <div className="aibot-list-wrapper">
      <div className="aibot-list">
        {aiList?.map((item, idx) => (
          <div className="aibot-list--item" key={idx}>
            <AiBot
              data={item}
              hideOwner={props.hideOwner}
              hideCategory={props.hideCategory}
              hideDescription={props.hideDescription}
              isFromCreatorPage={props.isFromCreatorPage}
            />
          </div>
        ))}
      </div>

      {!hidePagination && (
        <div className="aibot-list-pagenation">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<ChevronRightIcon className="h-4 w-4" />}
            forcePage={currentPage - 1}
            onClick={handlePage}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel={<ChevronLeftIcon className="h-4 w-4" />}
            renderOnZeroPageCount={null}
            containerClassName="inline-flex -space-x-px text-sm"
            pageLinkClassName="pagenation__item"
            previousLinkClassName="pagenation__item"
            nextLinkClassName="pagenation__item"
            breakLinkClassName="pagenation__item"
            activeLinkClassName="active"
            disabledLinkClassName="disabled"
          />
        </div>
      )}
    </div>
  );
};

export default List;
