import "./styles.scss";

const CookiePolicyPage = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">Cookie Policy</h1>
        <h2 className="mb-10">Cookie Policy Last Updated: 5th May 2023</h2>
        <p>
          At TAISK, we are committed to protecting your privacy and ensuring
          that your online experience is transparent and secure. This Cookie
          Policy explains how we use cookies and similar technologies on our
          website and services to enhance your user experience and help us
          understand how our platform is being used. By using our website and
          services, you are agreeing to our use of cookies as described in this
          policy.
        </p>

        <h2 className="mt-6 mb-4">What are cookies?</h2>
        <p>
          Cookies are small text files that are placed on your computer or
          device by websites you visit. They are widely used to make websites
          work, enhance their performance, or provide information to the site
          owners. Cookies can be "persistent" or "session" cookies. Persistent
          cookies remain on your device when you go offline, while session
          cookies are deleted as soon as you close your web browser.
        </p>

        <h2 className="mt-6 mb-4">How does TAISK use cookies?</h2>
        <p>TAISK uses cookies for several purposes, including:</p>
        <ol>
          <li>
            Essential Cookies: These cookies are necessary for the proper
            functioning of our website and services. They enable you to navigate
            the site, use its features, and access secure areas. Without these
            cookies, some of our services may not be available to you.
          </li>
          <li>
            Performance and Analytics Cookies: These cookies help us understand
            how visitors interact with our website by collecting information
            about the pages visited, time spent on the site, and any errors
            encountered. This information helps us improve our website's
            performance and user experience.
          </li>
          <li>
            Functionality Cookies: These cookies remember your preferences and
            settings (such as language, font size, or other display preferences)
            to provide a more personalized experience. They may also be used to
            remember changes you have made to customizable areas of the website.
          </li>
          <li>
            Targeting and Advertising Cookies: These cookies are used to deliver
            relevant ads to you based on your interests and browsing behaviour.
            They may also be used to limit the number of times you see an
            advertisement and measure the effectiveness of advertising
            campaigns.
          </li>
        </ol>

        <h2 className="mt-6 mb-4">Third-Party Cookies</h2>
        <p>
          We may also use third-party cookies to help us deliver targeted
          advertising, analyse site traffic, and understand user behaviour.
          These third-party service providers may have their own privacy
          policies and cookie policies, and we encourage you to review them.
        </p>

        <h2 className="mt-6 mb-4">Managing your cookie preferences</h2>
        <p>
          You have the option to manage your cookie preferences through your web
          browser settings. Most browsers allow you to block or delete cookies,
          but please note that disabling essential cookies may affect the
          functionality of our website and services. To learn more about how to
          manage cookies on popular browsers, please visit the following links:
        </p>
        <ol>
          <li>Google Chrome: https://support.google.com/chrome/answer/95647</li>
          <li>
            Mozilla Firefox:
            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          </li>
          <li>
            Microsoft Internet Explorer:
            https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
          </li>
          <li>
            Apple Safari:
            https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </li>
        </ol>

        <h2 className="mt-6 mb-4">Changes to our Cookie Policy</h2>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review this policy periodically to stay
          informed about our use of cookies and related technologies.
        </p>

        <h2 className="mt-6 mb-4">Contact Us</h2>
        <p>
          If you have any questions or concerns about our use of cookies or this
          Cookie Policy, please contact us at alex@taisk.com.
        </p>
        <p>Thank you for choosing TAISK.</p>
      </div>
    </div>
  );
};

export default CookiePolicyPage;
