import "./styles.scss";
import { useState } from "react";
import { emailToDownloadGuide } from "../../common/api/marketplace";

const DownloadEmail = ({data}) => {
  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState("");
  const [doneDownload, setDoneDownload] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  const checkEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const handleSubscribe = () => {
    let mainCategoryId = 1;
    if (data === "educators") {
      mainCategoryId = 1;
    } else if (data === "startups") {
      mainCategoryId = 2;
    } else if (data === "nonProfits") {
      mainCategoryId = 3;
    }
    if (!email) return;
    setDoneDownload("");
    const validEmail = checkEmail(email);
    setHasError(validEmail ? "" : "Please provide valid email address");
    if (validEmail) {
      setIsDownloading(true);
      emailToDownloadGuide({ email, main_category_id: mainCategoryId })
        .then((res) => {
          if (res.status && res.status !== 200) {
            setHasError(res?.data?.message);
            return;
          }
          setEmail("");
          setDoneDownload(res?.message);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") handleSubscribe();
  };

  return (
    <div className="email-wrapper relative w-full">
      <div className="emailDiv-email-section">
        <div className="email-section container">
          <div className="email-form">
            <div className="email-input">
              <input
                type="email"
                placeholder="Email your email"
                className="email-input-field"
                value={email}
                onChange={(e) => {
                  setHasError("");
                  setDoneDownload("");
                  setEmail(e.target.value);
                }}
                onKeyDown={handleOnKeyDown}
              />
              <button disabled={isDownloading} onClick={handleSubscribe}>
                Download
              </button>
            </div>
            {hasError && <span className="error">{hasError}</span>}
            {doneDownload && (
              <span className="text-green-800">{doneDownload}</span>
            )}
            <p>
              We care about your data in our{" "}
              <a href="/privacy-policy" target="_blank">
                privacy policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadEmail;
