import CryptoJS from 'crypto-js';

const encryptText = (text, password) => {
    try {
        const salt = CryptoJS.lib.WordArray.random(128 / 8);
        const key = CryptoJS.PBKDF2(password, salt, {
            keySize: 192 / 32,
            iterations: 1000
        });

        const iv = CryptoJS.lib.WordArray.random(128 / 8);
        const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });

        return `${iv.toString(CryptoJS.enc.Hex)}/+${salt.toString(CryptoJS.enc.Hex)}/+${encrypted.toString()}`;
    } catch (error) {
        throw new Error(`Encryption error: ${error.message}`);
    }
};

const decryptText = (encryptedText, password) => {
    try {
        const [ivHex, saltHex, encrypted] = encryptedText.split('/+');
        // Check if all required parameters are provided
        if (!encrypted || !password || !ivHex || !saltHex) {
            throw new Error('Missing required parameters for decryption');
        }

        const salt = CryptoJS.enc.Hex.parse(saltHex);
        const key = CryptoJS.PBKDF2(password, salt, {
            keySize: 192 / 32,
            iterations: 1000
        });

        const iv = CryptoJS.enc.Hex.parse(ivHex);
        const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });

        const result = decrypted.toString(CryptoJS.enc.Utf8);
        
        // Check if the decryption result is valid
        if (!result) {
            throw new Error('Decryption failed: Invalid result');
        }

        return result;
    } catch (error) {
        console.error('Decryption error:', error);
        throw new Error(`Decryption error: ${error.message}`);
    }
};


export { encryptText, decryptText };