import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { CiSearch } from "react-icons/ci";

// images
import OpenAiIcon from "../../../assets/icons/open-ai.png";
import PerplexityIcon from "../../../assets/icons/perplexity.png";
import AnthropicIcon from "../../../assets/icons/anthropic.png";
import WondeIcon from "../../../assets/icons/wonde.png";
import GeminiIcon from "../../../assets/icons/gemini.png";
import GmailIcon from "../../../assets/icons/gmail.png";
import GoogleDocsIcon from "../../../assets/icons/google-docs.png";
import GoogleSheetsIcon from "../../../assets/icons/google-sheets.png";
import { categories } from "../../../common/utils/category";
import BuildWith from "../sections/BuildWith/BuildWith";

import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const Headline = (props) => {
  const { testimonials } = props;

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const subCategoriesMenu = useMemo(() => {
    return categories.flatMap((category) => {
      return category.roles.map((subCategory) => ({
        name: subCategory.name,
        id: subCategory.sub_id,
        main_id: subCategory.main_id,
      }));
    });
  }, [categories]);

  const getCategoryNameBySubCategory = (subCategoryName) => {
    for (const category of categories) {
      for (const subCategory of category.roles) {
        if (subCategory.name === subCategoryName) {
          return category.name;
        }
      }
    }
    return null;
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 960 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 960, min: 720 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  const logosSetup = {
    desktop: {
      breakpoint: { max: 5000, min: 960 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 960, min: 720 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 3,
    },
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!search) {
      setShowError(true);
      return;
    }
    navigate(`/search/library/${search}`);
  };

  const handleCategorySearch = (subCategory) => {
    navigate(
      `/library/${getCategoryNameBySubCategory(subCategory.name)}/${
        subCategory.id
      }`,
    );
  };

  const headlineSearchBoxCategories = () => {
    return subCategoriesMenu
      .sort(() => 0.5 - Math.random())
      .slice(0, 3)
      .map((subcategory, index) => (
        <span
          key={index}
          onClick={() => {
            handleCategorySearch(subcategory);
          }}
          style={{
            fontSize: "1rem",
            width: "100%",
            display: "inline-block",
            padding: "0.5rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
            textAlign: "center",
            margin: "0.25rem 0",
            cursor: "pointer",
          }}
        >
          {subcategory.name}
        </span>
      ));
  };

  const handleBuildYourOwn = () => {
    setLoading(true);
    window.location.href = `${import.meta.env.VITE_SERVER_URL}/register`;
  };

  const handleBookDemo = () => {
    setLoading(true);
    navigate("/book-a-demo");
  };

  return (
    <div className="relative">
      <Carousel
        autoPlay={true}
        autoPlaySpeed={3500}
        draggable={false}
        responsive={responsive}
        infinite={true}
        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        containerClass="headline-section carousel-container"
      >
        {testimonials?.map((item, idx) => (
          <div
            key={idx}
            className="headline-cover h-full w-full object-cover"
            style={{ backgroundColor: `${item?.hex_color}` }}
          >
            <div
              className="headline-cover-image relative h-full w-full object-cover lg:block hidden"
              style={{ backgroundImage: `url(${item?.background_url})` }}
            >
              <div className="container mx-auto h-full relative">
                <div className="headline-cover-info shadow-xl">
                  <div
                    className="info-avatar"
                    style={{ backgroundImage: `url(${item?.logo_url})` }}
                  ></div>
                  <div className="description">
                    <h2 className="mb-1 tracking-wide">{item?.content}</h2>
                    <div className="flex items-center gap-2">
                      <p>
                        {item?.name} | <b>{item?.position}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="headline-content absolute w-full h-full md:top-0 top-0 left-0">
        <div className="container mx-auto h-full flex">
          <div className="lg:w-2/3 w-full h-[calc(100%-64px)] z-25 flex flex-col gap-6 justify-center">
            <h1 className="text-6xl text-center md:text-left text-white font-bold">
                Custom AI For Any Task
              <br />
            </h1>
            <h2 className="text-2xl text-center md:text-left text-white font-bold">
              Save Time On Your Tasks With AI ChatBots and Agents
            </h2>
            <div className="flex flex-col items-center md:flex-row gap-4">
              <button
                className="start-building-btn text-2xl"
                onClick={handleBuildYourOwn}
              >
                {loading ? <PageLoader /> : "Start Building Your AI For Free"}
              </button>
              <button
                className="start-building-btn solid text-2xl "
                onClick={handleBookDemo}
              >
                {loading ? <PageLoader /> : "Book a Demo"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <BuildWith />
    </div>
  );
};

export default Headline;
