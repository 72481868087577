import { ImQuill } from "react-icons/im";
import { FaUserCircle } from "react-icons/fa";

const blogPost = [
  {
    id: 1,
    img: "https://static.wixstatic.com/media/4d69d5_b823aed3ad3642b6b30791ed1fb3550d~mv2.jpg/v1/fill/w_454,h_341,fp_0.50_0.50,q_90,enc_auto/4d69d5_b823aed3ad3642b6b30791ed1fb3550d~mv2.jpg",
    publishedBy: "Linda",
    link: "https://labs.taisk.com/post/taisk-ai-in-education-bbc",
    title: "TAISK & AI in Education | BBC",
    description: "TAISK subscriber and Cottesmore School headmaster, Tom Rogerson discusses using AI in schools."
  },
  {
    id: 2,
    img: "https://static.wixstatic.com/media/4d69d5_904648aa10654e308c43081299133d41~mv2.png/v1/fill/w_454,h_341,fp_0.50_0.50,q_95,enc_auto/4d69d5_904648aa10654e308c43081299133d41~mv2.png",
    publishedBy: "Linda",
    link: "https://labs.taisk.com/post/taisk-named-one-of-scotland-s-most-interesting-ai-startups",
    title: "TAISK named One of Scotland’s Most Interesting AI Startups",
    description: "TAISK named One of Scotland’s Most Interesting AI Startups"
  },
  {
    id: 3,
    img: "https://static.wixstatic.com/media/4d69d5_3db78cd1046b459a8c73e6f1f009e93a~mv2.jpg/v1/fill/w_454,h_341,fp_0.50_0.50,q_90,enc_auto/4d69d5_3db78cd1046b459a8c73e6f1f009e93a~mv2.jpg",
    publishedBy: "Alexander Fahie",
    link: "https://labs.taisk.com/post/the-ai-imperative-why-schools-must-have-a-seat-at-the-table",
    title: "The AI Imperative: Why Schools Must Have A Seat At The Table",
    description: "In the future, every school will use AI. They may not call it AI, which would be great, but they’ll use it."
  }
];

const Blog = () => {

  return (
    <div className="bg-[#f2f6ff] pt-16 pb-6">
      <div className="container mx-auto sm:px-0 px-5">

        {blogPost.map((post) => (
          <a href={post.link} target="_blank" key={post.id} className="block md:flex gap-x-12 border-2 mb-14">
            <div className="w-full md:w-[40%]">
              <img
                src={post.img} 
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="w-full md:w-[60%] py-6 px-5 md:px-0">
              <div className="flex items-center gap-x-2 mb-5">
                <FaUserCircle className="w-8 h-8" />
                <span className="flex items-center text-sm gap-x-1">{post.publishedBy} <ImQuill/></span>
              </div>
              <div className="w-full md:w-[80%]">
                <h2 className="font-bold text-xl md:text-5xl mb-5">{post.title}</h2>
                <p className="text-sm md:text-lg">{post.description}</p>
              </div>
            </div>
          </a>
        ))}

      </div>
    </div>
  );
};

export default Blog;
