export const details = [
  {
    name: "Customer Support",
    description:
      "TAISK empowers businesses to provide exceptional customer support by leveraging the power of customisable AI Bots and Agents. With TAISK, your company can create tailored, intelligent chatbots that cater to specific customer needs, ensuring efficient and personalised support experiences and automated follow-ups.",
    list: [
      "Instant, 24/7 assistance",
      "Personalised support at scale",
      "Streamlined support processes"
    ]
  },
  {
    name: "Education",
    description:
      "TAISK can significantly alleviate teacher workloads and augmenting education by providing customisable AI Bots and Agents. These intelligent tools can assist teachers in various tasks, allowing them to focus on delivering high-quality, personalised instruction to their students.",
    list: [
      "Automated grading and feedback",
      "Personalised learning support",
      "Streamlined administrative tasks"
    ]
  },
  {
    name: "Entrepreneurship",
    description:
      "TAISK enables entrepreneurs to achieve more without the need for additional funding by providing customisable AI Bots and Agents. These powerful tools can automate various tasks, streamline processes, and provide intelligent assistance, allowing entrepreneurs to focus on growing their businesses with minimal resources.",
    list: [
      "Automated customer support",
      "Streamlined marketing efforts",
      "Intelligent task automation"
    ]
  },
  {
    name: "Agents",
    description:
      "TAISK can revolutionise Human Resources by providing customizable AI bots and agents that enhance employee support, facilitate workplace learning, and automate tasks. These intelligent tools can improve employee engagement, productivity, and overall satisfaction while optimising HR processes.",
    list: [
      "Personalised employee assistance",
      "Intelligent learning and development",
      "Automated HR tasks and processes"
    ]
  },
  {
    name: "Just For Fun",
    description:
      "Have some fun with TAISK. Create and interact with historical characters, generating unique images, and build, share and play engaging games. These entertaining features provide users with endless opportunities for enjoyment and creative expression.",
    list: [
      "Engaging conversations with historical figures",
      "Generating personalised, creative images",
      "Playing interactive, AI-powered games"
    ]
  },
  {
    name: "Programming",
    description:
      "TAISK empowers programmers to have fun while enhancing their coding experience by leveraging AI-powered tools. These Bots and Agents can assist in various aspects of programming, from writing clean, efficient code to conducting thorough quality assurance checks, making the development process more enjoyable and productive.",
    list: [
      "AI-assisted code generation and optimisation",
      "Automate bug detection and suggestions",
      "AI-powered code review and quality assurance"
    ]
  },
  {
    name: "Sales And Marketing",
    description:
      "TAISK provides a powerful platform for sales and marketing professionals to create and deploy customised AI bots and agents tailored to their specific needs. By leveraging TAISK's AI capabilities, businesses can automate and optimise various sales and marketing processes, leading to increased efficiency, improved customer engagement, and ultimately, better results.",
    list: [
      "AI-powered lead generation and qualification",
      "Personalised customer support and engagement",
      "Automated marketing campaign optimization"
    ]
  },
  {
    name: "Virtual Assistant",
    description:
      "TAISK enables users to create and customise powerful virtual assistants that can streamline their daily tasks and boost productivity. By leveraging AI Bots and Agents on TAISK, these virtual assistants can intelligently label emails, schedule appointments, and even draft responses, saving users valuable time and effort.",
    list: [
      "AI-powered email labelling and organisation",
      "Automated appointment scheduling and reminders",
      "Intelligent response drafting based on email content"
    ]
  },
  {
    name: "Writing And Translation",
    description:
      "TAISK offers a versatile platform for users to create and utilise AI-powered writing and translation tools. Whether you need help with content creation, editing, or translating text from one language to another, TAISK's customisable AI Bots and Agents can assist you in achieving your writing and translation goals efficiently.",
    list: [
      "AI-assisted content creation and editing",
      "Multilingual translation Speech",
      "Context-aware grammar and style suggestions"
    ]
  }
];
