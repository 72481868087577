import "./styles.scss";
import PreviewDetails from "../../common/components/sections/PreviewDetails";
import List from "../../common/components/AiLibrary/components/List";
import PageLoader from "../../common/components/PageLoader";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getCreatorSettings } from "../../common/api/creators";

const Library = ({ embedded }) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: creatorData, loading: creatorLoading } = useQuery({
    queryKey: ["creator-package", slug],
    queryFn: () => getCreatorSettings(slug),
    options: {
      refetchInterval: 3000000,
      staleTime: 3000000,
    },
  });

  if (creatorLoading || !creatorData) return <PageLoader />;

  const content = creatorData.result[0];
  const handleGetStarted = () => {
    window.location.href = `${import.meta.env.VITE_SERVER_URL}/login?creatorPackage=${content.packageId}`;
  };
  if (!content?.isPublished) {
    navigate("/");
  }
  const price = parseFloat(content.price.replace("£", ""), 16);
  const botList = content.data.botsToShow.map((bot) => ({
    ...bot,
    tags: "AI Bot",
  }));
  const agentList = content.data.agentsToShow.map((agent) => ({
    ...agent,
    tags: "AI Agent",
  }));
  const list = [...botList, ...agentList];

  return (
    <div className="library-page">
      <div className="library-banner">
        <img
          src={content?.data?.bannerImage || ""}
          alt="Banner"
          className="banner-image"
        />
        <h2>{content?.data?.bannerText}</h2>
      </div>

      <div className="ai-library-container">
        <div className="container mx-auto sm:px-0 px-5">
          {Boolean(list?.length) ? (
            <List
              list={list}
              hidePagination={true}
              hideOwner
              hideCategory
              hideDescription
              isFromCreatorPage
            />
          ) : (
            <PageLoader className="h-8" />
          )}
        </div>
      </div>

      {embedded ? null : (
        <PreviewDetails
          imgUrl={content.data.footerImage}
          title=""
          description={content.data.footerBio}
          list={[]}
          handleGetStarted={handleGetStarted}
          hideButton={false}
          buttonText={`BUY PACKAGE`}
          purchaseButton
          price={`£${price.toFixed(2)}`}
        />
      )}
    </div>
  );
};

export default Library;
