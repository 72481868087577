import MarkdownRenderer from "../../../components/MarkdownRenderer";
import "../styles.scss";

const AiDescriptionDetails = ({ bio }) => {
  return (
    <div className="ai-description-details">
      <h2 className="mb-1 text-xl font-semibold text-sitePrimary">The Bio</h2>
      {bio ? (
        <MarkdownRenderer text={bio} />
      ) : (
        <p>{`This builder hasn't shared any bio yet`}</p>
      )}
    </div>
  );
};

export default AiDescriptionDetails;
