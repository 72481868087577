import { legacy_createStore as createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import mainReducer from "./reducers";
const initialState = {};

const middlewares = [thunk];

export const store = createStore(
  mainReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);
