import { SET_CATEGORIES_MENU, SET_TESTIMONIALS } from "../type";

export const setTestimonials = (dispatch, data) => {
  dispatch({
    type: SET_TESTIMONIALS,
    payload: data
  });
};

export const setCategoriesMenu = (dispatch, data) => {
  dispatch({
    type: SET_CATEGORIES_MENU,
    payload: data
  });
};
