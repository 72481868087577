import React, { useEffect } from "react";
import './styles.scss'

const BookDemo = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.typeform.com/next/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        className="h-[calc(100dvh-128px)] mt-32 w-4/5 mx-auto iframe-container"
        data-tf-live="01J4467EJV0NGVF02G2G5QK5SN"
      ></div>
    </>
  );
};

export default BookDemo;
