import "./styles.scss";

const DataSecurityPolicy = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">Data Security Policy</h1>
        <h2 className="mb-10">Last Updated: 1st March 2024</h2>

        <h2 className="mt-6 mb-4">1. Policy Statement</h2>
        <p>
          TAISK. is committed to ensuring the security, integrity, and
          confidentiality of all data handled by TAISK, our AI workshop. We
          adhere to the highest standards of data protection, following the
          principles laid out in the UK's Data Protection Act 2018 and the EU's
          General Data Protection Regulation (GDPR).
        </p>

        <h2 className="mt-6 mb-4">2. Scope</h2>
        <p>
          This policy applies to all data, including personal and sensitive
          information, processed, stored, or transmitted by TAISK.
        </p>

        <h2 className="mt-6 mb-4">3. Data Protection and Security</h2>
        <ul>
          <li>
            <b>3.1 Encryption and Data Storage:</b> We employ end-to-end
            encryption for all data transmissions and use secure cloud storage
            solutions provided by AWS RDS databases to protect the data under
            our care.
          </li>
          <li>
            <b>3.2 Server Location:</b> Our servers are located within the
            United Kingdom, ensuring data is protected under stringent UK data
            protection laws and the GDPR.
          </li>
          <li>
            <b>3.3 Access Controls and Audits:</b> Strict access controls are in
            place to ensure only authorised personnel have access to user data.
            Regular security audits are conducted to enhance our protective
            measures continually.
          </li>
          <li>
            <b>3.4 Data Usage:</b> As per OpenAI's confirmation on March 1st,
            2023, data from API calls, including those from TAISK, are not used
            for training their machine learning models.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">4. Organisational Measures</h2>
        <ul>
          <li>
            <b>4.1 ICO Membership:</b> TAISK. is a registered member of the
            Information Commissioner's Office (ICO), affirming our commitment to
            data protection and compliance with UK data protection laws.
          </li>
          <li>
            <b>4.2 Training and Awareness:</b> All employees and contractors are
            provided with data protection training, ensuring awareness and
            adherence to this policy.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">5. User Data Rights</h2>
        <p>
          We recognise and uphold users' rights over their personal data,
          ensuring they can exercise their rights to access, correct, and erase
          their data as provided by applicable data protection laws.
        </p>

        <h2 className="mt-6 mb-4">6. Data Breach Response</h2>
        <p>
          In the unlikely event of a data breach, we will promptly take remedial
          actions and notify affected parties and the ICO, in accordance with
          legal requirements.
        </p>

        <h2 className="mt-6 mb-4">7. Policy Review and Updates</h2>
        <p>
          This policy will be reviewed regularly and updated as necessary to
          reflect changes in our practices or the legal landscape.
        </p>

        <h2 className="mt-6 mb-4">8. Contact Information</h2>
        <p>
          For any inquiries or concerns regarding data security, please contact
          us at alex@taisk.com.
        </p>
      </div>
    </div>
  );
};

export default DataSecurityPolicy;
