import "./styles.scss";

const PrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">Privacy Policy</h1>
        <h2 className="mb-10">Last Updated: 1st March 2024</h2>
        <h2 className="mt-6 mb-4">1. Introduction</h2>
        <p>
          At TAISK, we are committed to protecting your privacy and ensuring
          that your personal information is handled securely and responsibly.
          This Privacy Policy (the "Policy") explains how we collect, use,
          disclose, and protect your personal information when you use our
          website, products, and services (collectively, the "Services").
        </p>
        <p>
          By using our Services, you agree to the collection, use, and
          disclosure of your personal information in accordance with this
          Policy.
        </p>

        <h2 className="mt-6 mb-4">2. Information We Collect</h2>
        <p>
          We may collect the following types of personal information from you:
        </p>
        <ol>
          <li>
            Personal Information: This includes your name, email address, phone
            number, job title, company name, and other information you provide
            when you register for an account, sign up for our newsletter, or
            contact us.
          </li>
          <li>
            Usage Information: We may collect information about how you use our
            Services, such as the pages you visit, the content you access, and
            the actions you perform.
          </li>
          <li>
            Technical Information: We may collect technical information about
            your device, browser, and internet connection, such as your IP
            address, browser type, operating system, and other diagnostic data.
          </li>
          <li>
            Cookies and Similar Technologies: We may use cookies and similar
            technologies to collect information about your preferences, browsing
            history, and other usage data. Please refer to our Cookie Policy for
            more information on how we use cookies and how you can manage your
            preferences.
          </li>
        </ol>

        <h2 className="mt-6 mb-4">3. How We Use Your Information</h2>
        <p>We use your personal information for the following purposes:</p>
        <ol>
          <li>
            To provide, maintain, and improve our Services, including
            personalizing your experience and delivering relevant content.
          </li>
          <li>
            To communicate with you about our Services, such as sending you
            updates, promotional materials, and responding to your inquiries.
          </li>
          <li>
            To analyse and monitor the usage of our Services, identify trends,
            and improve the overall performance and user experience.
          </li>
          <li>
            To ensure the security and integrity of our Services, detect and
            prevent fraud or unauthorized activities, and enforce our Terms of
            Service.
          </li>
          <li>
            To comply with legal obligations and protect our rights and
            interests, including responding to subpoenas, court orders, or other
            legal processes.
          </li>
        </ol>

        <h2 className="mt-6 mb-4">4. Disclosure of Your Information</h2>
        <p>
          We may disclose your personal information to the following parties:
        </p>
        <ol>
          <li>
            Service Providers: We may share your personal information with
            third-party service providers who perform functions on our behalf,
            such as hosting, analytics, payment processing, and customer
            support.
          </li>
          <li>
            Business Transfers: We may disclose your personal information in
            connection with a merger, acquisition, or sale of our assets, as
            required by applicable law.
          </li>
          <li>
            Legal Compliance: We may disclose your personal information if
            required by law, regulation, or legal process, or to protect our
            rights, property, or the safety of our users and the public.
          </li>
          <li>
            With Your Consent: We may share your personal information with other
            parties if we have obtained your prior consent.
          </li>
        </ol>

        <h2 className="mt-6 mb-4">5. Security of Your Information</h2>
        <p>
          We implement appropriate technical and organizational measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, or destruction. However, please note that no
          method of transmission or storage is completely secure, and we cannot
          guarantee the absolute security of your personal information.
        </p>

        <h2 className="mt-6 mb-4">6. Data Retention</h2>
        <p>
          We retain your personal information for as long as necessary to fulfil
          the purposes for which it was collected, comply with our legal
          obligations, resolve disputes, and enforce our agreements. When we no
          longer need your personal information, we will securely delete or
          anonymize it.
        </p>

        <h2 className="mt-6 mb-4">7. Your Rights and Choices</h2>
        <p>
          You have the right to access, update, correct, or delete your personal
          information, as well as object to or restrict the processing of your
          personal information, in accordance with applicable laws. To exercise
          these rights, please contact us at alex@taisk.com.
        </p>

        <h2 className="mt-6 mb-4">8. Changes to Our Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices, applicable laws, or for other operational, legal, or
          regulatory reasons. We will notify you of any material changes by
          posting the updated Privacy Policy on our website or by sending you an
          email notification. Your continued use of our Services after we have
          posted an updated Privacy Policy constitutes your acceptance of the
          changes.
        </p>

        <h2 className="mt-6 mb-4">9. International Data Transfers</h2>
        <p>
          Our Services are hosted and operated in the United Kingdom. However,
          we may transfer your personal information to countries outside of your
          country of residence, which may have different data protection laws.
          By using our Services, you consent to the transfer of your personal
          information to other countries as described in this Policy.
        </p>

        <h2 className="mt-6 mb-4">10. Third-Party Websites and Services</h2>
        <p>
          Our Services may contain links to third-party websites and services
          that are not owned or controlled by TAISK. We are not responsible for
          the privacy practices or the content of such websites and services. We
          encourage you to review the privacy policies of these third-party
          websites and services before providing them with any personal
          information.
        </p>

        <h2 className="mt-6 mb-4">11. Children's Privacy</h2>
        <p>
          Our Services are not intended for or directed at children under the
          age of 13, and we do not knowingly collect personal information from
          children under 13. If you are a parent or guardian and believe that
          your child has provided us with personal information without your
          consent, please contact us at [email address] or [postal address], and
          we will take steps to delete such information from our systems.
        </p>

        <h2 className="mt-6 mb-4">12. Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy or our
          data handling practices, please contact us at: alex@taisk.com. We are
          committed to working with you to resolve any concerns or complaints
          related to your privacy and our collection or use of your personal
          information.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
