import React, { useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Image from '@/common/components/Image/index'

// className="flex flex-col rounded-md border-2 p-6 border-sitePrimary hover:bg-[radial-gradient(ellipse_at_center, _var(--tw-gradient-stops)) from-secondary from-0% to-transparent to-100%]"
const Item = (props) => {
  const { icon, name, sub_id } = props.info;
  return (
    <div className="flex flex-col items-center">
      <a
        href={`/library/${props.category}/${sub_id}`}
        className="flex flex-col rounded-md border-2 p-6 bg-radial-no-gradient border-sitePrimary hover:bg-hover-gradient h-64 w-[220px] min-w-[220px] transition-all duration-300"
      >
        <img src={icon} className="w-32 h-32 m-auto" />
        <p className="text-center">{name}</p>
      </a>
    </div>
  );
};

const InteractiveIcons = (props) => {
  const list = useMemo(() => props.list || [], [props.list]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 960 },
      items: 5,
    },
    lg: {
      breakpoint: { max: 1140, min: 961 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 960, min: 720 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="pt-8">
      <Carousel
        draggable={false}
        responsive={responsive}
        infinite={true}
        containerClass="popular-aibot-carousel carousel-container mb-10"
        itemClass="px-1 w-32 min-w-32"
      >
        {list?.map((_item, idx) => (
          <>
            <Item info={_item} key={idx} category={props.category} />
          </>
        ))}
      </Carousel>
    </div>
  );
};

export default InteractiveIcons;
