import axios from "axios";
import { contentfulService } from "../utils/contentful";

// HELPERS
export function getImageURL(data) {
  const imagePath = (data?.fields?.file?.url || "").replace("//", "");

  return imagePath ? `https://${imagePath}` : "";
}

/*
 * category: string;
 */

const getQuery = async (query) => {
  var base_url = window.location.origin;

  if (import.meta.env.VITE_ENV === "local") {
    delete query.key;
    return contentfulService.getEntries(query);
  }


  return axios.post(`${base_url}/api/contentful`, {
    query,
  });
};

export const getPage = async (category) => {
  const query = {
    content_type: "homepage",
    "fields.slug": category,
    key: "homepage " + category,
  };

  return getQuery(query).then((res) => {
    const items = res?.data?.items || res?.items;
    const data = items[0].fields;
    return {
      ...data,
      heroBanner: getImageURL(data.heroBanner),
      promotionalImage: getImageURL(data.promotionalImage),
    };
  });
};

export const getPageContent = async (slug) => {
  const query = {
    content_type: "pageContainer",
    "fields.slug": slug,
    key: "pageContainer " + slug,
  };
  return getQuery(query).then((res) => {
    const items = res?.data?.items || res?.items;
    const data = items[0].fields;
    return {
      ...data,
      heroBanner: getImageURL(data.heroBanner),
      promotionalImage: getImageURL(data.promotionalImage),
    };
  });
};

export const getPluginPage = async (plugin) => {
  const query = {
    content_type: "pluginPage",
    "fields.slug": plugin,
    key: "plugin " + plugin,
  };
  return getQuery(query).then((res) => {
    const items = res?.data?.items || res?.items;
    const data = items[0].fields;
    return {
      ...data,
      heroBanner: getImageURL(data.heroBanner),
      itemImage: getImageURL(data.itemImage),
    };
  });
};

export const getPlugins = async () => {
  const query = {
    content_type: "pluginPage",
    key: "plugins",
  };
  return getQuery(query).then((res) => {
    const items = res?.data?.items || res?.items;
    const data = items.map((x) => ({
      ...x.fields,
      itemImage: getImageURL(x.fields.itemImage),
    }));
    return data;
  });
};
