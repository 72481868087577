import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getPluginPage } from "@/common/api/contentful.js";
import { useParams } from "react-router-dom";
import ContentfulRenderer from "../../components/ContentfulRenderer";
import { EXTRA_URLs } from "../../utils/constants";
import Arrow from "@/assets/svgs/arrow";
import Subscription from "@/common/components/sections/Subscription";
import BuildWith from "../../common/components/sections/BuildWith/BuildWith";
import PageLoader from "../../common/components/PageLoader";

const FYPContext = React.createContext();

export const FYPProvider = ({ children }) => {
  const { plugin } = useParams();
  const [pageContent, setPageContent] = useState({});

  const [loading, setLoading] = useState(false);
  const fetchPageContent = useCallback(async (plugin) => {
    setLoading(true);
    const res = await getPluginPage(plugin);
    setPageContent(res);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchPageContent(plugin);
  }, [plugin]);

  return (
    <FYPContext.Provider
      value={{
        pageContent,
        loading,
      }}
    >
      {children}
    </FYPContext.Provider>
  );
};

const startedBtn = (
  <a href={EXTRA_URLs.GET_STARTED_PATH} className="inline-flex pt-8">
    <div className="flex bg-sitePrimary hover:bg-primary hover:text-sitePrimary text-white text-lg p-4 items-center rounded-md gap-4 justify-between transition-all duration-300">
      Get started for free <Arrow className="h-4 w-4" />
    </div>
  </a>
);

export const ColumnContent = ({ left, right, showStartedOnLeft }) => {
  return (
    <div
      className={`flex flex-col gap-8 mb-16 lg:flex-row lg:items-center mt-2 ${!showStartedOnLeft ? "" : "flex-col-reverse"}`}
    >
      <div className="flex-1">
        <ContentfulRenderer data={left.content}>
          {showStartedOnLeft ? startedBtn : null}
        </ContentfulRenderer>
      </div>
      <div className="flex-1">
        <ContentfulRenderer data={right.content}>
          {!showStartedOnLeft ? startedBtn : null}
        </ContentfulRenderer>
      </div>
    </div>
  );
};

const ForYouPage = () => {
  const { pageContent = {}, loading } = useContext(FYPContext);

  if (!pageContent.slug) {
    return <></>;
  }

  const HeroBanner = (
    <div
      className={`w-full h-auto  relative p-8`}
      style={{ backgroundColor: pageContent.headerBgColor }}
    >
      <div className="flex-1 w-10/12 mx-auto flex justify-center h-full  items-center align-center flex-col md:flex-row gap-4">
        <div className="flex flex-1 md:justify-center w-[220px]">
          <img
            className=" w-full md:w-1/2 object-contain "
            src={pageContent.itemImage}
          />
        </div>
        <div className="flex flex-col flex-1 md:w-1/2 text-center md:text-left">
          <ContentfulRenderer data={pageContent.heroDescription?.content} />
        </div>
      </div>
    </div>
  );

  if (loading || !pageContent)
    return (
      <div>
        <PageLoader />
      </div>
    );

  return (
    <div className="min-h-10 lg:mt-[128px]">
      {HeroBanner}

      {[].length ? (
        <div className="w-full container mx-auto sm:px-0 px-5 flex flex-col m-auto mt-12">
          <ContentfulRenderer data={pageContent.categoryHeader?.content} />
          {/*
          <InteractiveIcons list={subNavData} category={subnav} />
             */}
        </div>
      ) : (
        <></>
      )}
      <main className="sm:p-14 p-8 w-full flex justify-center ">
        <div className="w-full container mx-auto sm:px-0 px-5">
          <ColumnContent
            left={pageContent.topLeftColumn}
            right={pageContent.topRightColumn}
            showStartedOnLeft
          />
          <ColumnContent
            left={pageContent.middleLeftColumn}
            right={pageContent.middleRightColumn}
          />
          {/*
             *
             *
             *<div className="pt-8 pb-16">
            <div className="flex items-center mt-4 w-full container mx-auto sm:px-0 px-5">
              <ContentfulRenderer data={pageContent.categoryHeader?.content} />
            </div>
            <div class="skeleton h-10 w-full bg-gray-200 rounded animate-pulse"></div>
            {/*
               *
            <Library embedded mainIdOverride={CATEGORY_IDS[category]} />
          </div>
             */}

          <div>
            {pageContent.footerVideoLink ? (
              <iframe
                width="100%"
                height="auto"
                style={{
                  aspectRatio: "16/9",
                }}
                src={pageContent.footerVideoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                seamless
                allowfullscreen
              ></iframe>
            ) : (
              <div></div>
            )}
          </div>

          <div className="mt-4">
            <ContentfulRenderer data={pageContent.footerText?.content} />
          </div>
        </div>
      </main>
    </div>
  );
};

const FYPContainer = (props) => {
  const pricingRef = useRef(null);
  return (
    <FYPProvider>
      <ForYouPage />
      {/* <HowTaiskWorks /> */}
      <Subscription innerRef={pricingRef} />
    </FYPProvider>
  );
};

export default FYPContainer;
