import { useState } from "react";
import axios from "axios";
import PageLoader from "../PageLoader";
import { getCategoriesMenu, getTestimonials } from "../../api/marketplace";
import { useDispatch } from "react-redux";
import {
  setCategoriesMenu,
  setTestimonials
} from "../../redux/actions/marketplace";

const API_INDEX = {
  GET_TESTIMONIALS: 0,
  GET_CATEGORIES_MENU: 1
};

const AppWrapper = (props) => {
  const dispatch = useDispatch();

  const [isPageReady, setIsPageReady] = useState(false);

  const loadData = () => {
    const initialApis = [getTestimonials(), getCategoriesMenu()];

    axios
      .all(initialApis)
      .then((responses) => {
        responses.forEach((_res, idx) => {
          handleSetInfo(_res, idx);
        });
      })
      .catch((errors) => {
        console.error("errors", errors);
      })
      .finally(() => {
        setIsPageReady(true);
      });
  };

  const handleSetInfo = (info, idx) => {
    switch (idx) {
      case API_INDEX.GET_TESTIMONIALS:
        setTestimonials(dispatch, info);
        break;
      case API_INDEX.GET_CATEGORIES_MENU:
        setCategoriesMenu(dispatch, info);
        break;
      default:
    }
  };

  useState(() => {
    loadData();
  }, []);

  return (
    <>{isPageReady ? <>{props.children}</> : <PageLoader fullPage={true} />}</>
  );
};

export default AppWrapper;
