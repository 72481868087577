import "./styles.scss";
import PreviewDetails from "../../common/components/sections/PreviewDetails";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { categories } from "../../common/utils/category";
import { MAIN_CATEGORY_IDS } from "../../common/hooks/useGetLibrary";
import { details } from "../../common/utils/categoryDetails";
import { getLibrary } from "../../common/api/library";
import { useDispatch } from "react-redux";
import List from "../../common/components/AiLibrary/components/List";
import PageLoader from "../../common/components/PageLoader";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import { EXTRA_URLs } from "../../utils/constants";

const Library = ({ embedded, mainIdOverride }) => {
  const { category, subCategory } = useParams();
  const navigate = useNavigate();

  const [banner, setBanner] = useState(null);
  const [subCategoryInfo, setSubCategoryInfo] = useState(null);
  const [infoBanner, setInfoBanner] = useState(null);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const listFilter = useMemo(() => {
    if (!category) {
      navigate("/");
      return;
    }
    const categoryInfo = categories.find((_i) => _i.name === category);

    setBanner(categories.find((_i) => _i.name === category)?.bannerImg);
    setInfoBanner({
      ...details.find((_i) => _i.name === category),
      banner: categoryInfo?.squareImg,
    });
    const mainId = mainIdOverride || categoryInfo?.roles[0]?.main_id;
    let subId = 0;
    if (subCategory) {
      const info = categoryInfo?.roles?.find(
        (_i) => _i.sub_id === parseInt(subCategory),
      );
      subId = info?.sub_id;
      setSubCategoryInfo(info);
    }

    const mainCategoryId = MAIN_CATEGORY_IDS[category];

    return {
      mainId,
      subId,
      mainCategoryId,
    };
  }, [category, subCategory]);

  useEffect(() => {
    getList(1, listFilter);
  }, [listFilter]);

  const handleLoadList = (page) => {
    setCurrentPage(page);
    getList(page, listFilter);
  };

  const getList = (page, filter) => {
    setIsLoading(true);
    const params = {
      page: page ?? 1,
      menu: null,
      subMenu: null,
      main_id: filter?.mainId,
      perpage: 8,
      sortby: 0,
      sub_id: filter?.subId ?? null,
      main_category_id: filter?.mainCategoryId,
    };
    getLibrary(params)
      .then((res) => {
        if (res && res?.data) {
          setList(res.data);
          setPageCount(res.pageCount);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleGetStarted = () => {
    window.location.href = EXTRA_URLs.GET_STARTED_PATH;
  };

  return (
    <div
      className="library-page"
      style={{
        marginTop: embedded ? "10px" : "",
      }}
    >
      <div
        className="library-banner"
        style={{
          backgroundImage: `url(${banner})`,
          display: embedded ? "none" : "",
        }}
      >
        <h2>{subCategory ? subCategoryInfo?.name : category}</h2>
      </div>

      <div className="ai-library-container">
        <div className="container mx-auto sm:px-0 px-5">
          {isLoading ? (
            <PageLoader />
          ) : Boolean(list?.length) ? (
            <List
              list={list}
              currentPage={currentPage}
              pageCount={pageCount}
              pageChange={handleLoadList}
              hidePagination={false}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full mt-[5rem] mb-[4rem]">
              <LightBulbIcon className="w-16 h-16" />
              <p className="text-lg font-medium text-center mt-4">
                "Nothing here yet, why not build your own?"
              </p>
              <Button
                className="ai-tabs-btn w-fit flex mt-6 md:flex-none flex-1 justify-center normal-case gap-x-3 items-center text-white font-medium text-[14px] md:text-[16px] py-3 px-5 bg-[#12062E] rounded-md"
                onClick={handleGetStarted}
              >
                Get started for free
              </Button>
            </div>
          )}
        </div>
      </div>

      {embedded ? null : (
        <PreviewDetails
          imgUrl={infoBanner?.banner}
          title=""
          description={infoBanner?.description}
          list={infoBanner?.list}
          hideButton={true}
        />
      )}
    </div>
  );
};

export default Library;
