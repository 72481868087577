import "./styles.scss";
// import videoSrc from "../../../../assets/video/TAISK.mp4";
import aiImg from "../../../../assets/images/how-to/2.png";
import customImg from "../../../../assets/images/how-to/3.png";
import securityImg from "../../../../assets/images/how-to/4.png";

const HowTaiskWorks = () => {
  const opts = {
    height: "auto",
    width: "100%",
  };

  return (
    <div className="how-taisk-works-section">
      <div className="container mx-auto sm:px-0 px-5">
        <h2>How TAISK works?</h2>
        <p>
          Join us on a journey with TAISK to discover AI's endless
          opportunities, where creativity meets real-world use.
        </p>
        <div className="video-works">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://www.youtube.com/embed/OgvDSgoPFcg"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Taisk"
            ></iframe>
          </div>
        </div>
        <div className="works-list">
          <div className="work-item">
            <div
              className="work-img"
              style={{ backgroundImage: `url(${aiImg})` }}
            ></div>
            <h2>Save Time With Your Own AI</h2>
            <p>Enhance productivity with the AI you need.</p>
          </div>
          <div className="work-item">
            <div
              className="work-img"
              style={{ backgroundImage: `url(${customImg})` }}
            ></div>
            <h2>Customisation</h2>
            <p>Make your AI look and behave the way you want.</p>
          </div>
          <div className="work-item">
            <div
              className="work-img"
              style={{ backgroundImage: `url(${securityImg})` }}
            ></div>
            <h2>Data Security</h2>
            <p>Your data is safe and secure on TAISK.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowTaiskWorks;
