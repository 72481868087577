import React from "react";
import Markdown from "react-markdown";

const MarkdownRenderer = (props) => {
  const Components = {
    h1: ({ children }) => (
      <h1 className="mb-1 text-5xl font-bold text-inherit">{children}</h1>
    ),
    h2: ({ children }) => (
      <h2 className="mb-1 text-4xl font-bold text-inherit">{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className="mb-1 text-3xl font-bold text-inherit">{children}</h3>
    ),
    h4: ({ children }) => (
      <h4 className="mb-1 text-2xl font-bold text-inherit">{children}</h4>
    ),
    h5: ({ children }) => (
      <h5 className="mb-1 text-1xl font-bold text-inherit">{children}</h5>
    ),
  };

  return <Markdown components={Components}>{props.text}</Markdown>;
};

export default MarkdownRenderer;
