import { useEffect, useState } from "react";
import "../styles.scss";
import { Input, Button, Textarea, Rating } from "@material-tailwind/react";
import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";
// import AWS from "aws-sdk";
import { S3 } from "@aws-sdk/client-s3";
import toast from "react-hot-toast";
import { IoMdRemoveCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import axios from "axios";
import { webAPI } from "../../../utils/constants";
import { decryptText } from "../../../utils/cipher";
const S3_BUCKET = import.meta.env.VITE_S3_PUBLIC;

let s3;
const encodedAccessKeyId = import.meta.env.VITE_ACCESS_KEY_ID;
const encodedSecretAccessKey = import.meta.env.VITE_ACCESS_SECRET_KEY;

const s3AccessKeyId = decryptText(encodedAccessKeyId, "taisk.com");
const s3SecretAccessKey = decryptText(encodedSecretAccessKey, "taisk.com");

s3 = new S3({
  // The key params is no longer supported in v3, and can be removed.
  // @deprecated The object needs to be passed to individual operations where it's intended.
  params: { Bucket: S3_BUCKET },

  region: import.meta.env.VITE_REGION,

  credentials: {
    accessKeyId: s3AccessKeyId,
    secretAccessKey: s3SecretAccessKey,
  },
});

const AiReviewDetails = (props) => {
  const { aibot, reviews, action } = props;

  const [file, setFile] = useState(null);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getImageFileObject = (imageFile) => {
    setFile(imageFile.file);
  };

  const runAfterImageDelete = () => {
    setFile(null);
  };

  const handleResetForm = () => {
    setFile(null);
    setUsername("");
    setMessage("");
    setRating(0);
  };

  const handleSubmit = async () => {
    if (username && message && rating && file) {
      setIsSubmitting(true);
      const formData = new FormData();
      const filename = aibot.uuid + file.name.replaceAll(" ", "");
      const params = {
        Bucket: S3_BUCKET,
        Key: filename,
        Body: file,
      };
      try {
        await // The `.promise()` call might be on an JS SDK v2 client API.
        // If yes, please remove .promise(). If not, remove this comment.
        s3.putObject(params).promise();
        const fileUrl = `https://${S3_BUCKET}.s3.${import.meta.env.VITE_REGION}.amazonaws.com/${filename}`;
        formData.append("file", fileUrl);
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error(error.message);
        setIsSubmitting(false);
        throw error;
      }

      formData.append("username", username);
      formData.append("message", message);
      formData.append("rating", rating);
      formData.append("chat", JSON.stringify(aibot));

      axios
        .post(webAPI.sendreview, formData)
        .then((res) => {
          if (res.data.success) {
            handleResetForm();
            toast.success("You successfully submitted");
            action();
          } else {
            toast.error(res.data.message);
          }
        })
        .finally(() => setIsSubmitting(false));
    } else {
      toast.error("Please fill in your information");
    }
  };

  return (
    <div className="ai-review-details">
      <h2>Write a Review</h2>
      <form className="ai-review-details__form max-w-screen">
        <h2>Your Name</h2>
        <Input
          size="lg"
          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {/* <h2>Your Title and Organisation</h2>
        <Input
          size="lg"
          placeholder="name@mail.com"
          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
        /> */}
        <h2>Upload Photo or Avatar</h2>
        <ImageUploader
          value={file}
          className="w-10 h-10 rounded-md"
          onFileAdded={(img) => getImageFileObject(img)}
          onFileRemoved={(img) => runAfterImageDelete(img)}
          deleteIcon={<IoMdRemoveCircle />}
          onChange={(val) => {
            console.log("onchange", val);
          }}
          uploadIcon={<MdAdd className="w-10 h-10" />}
        />
        <h2>Write Your Review</h2>
        <Textarea
          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
          placeholder="Type a messages..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="ratings">
          <h2>Overall rating</h2>
          <Rating
            value={rating}
            onChange={(value) => {
              setRating(value);
            }}
          />
        </div>
        <Button
          className="mt-2"
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </form>

      <div className="review-list-wrapper">
        <div className="review-list">
          {reviews?.map((_review, idx) => (
            <div className="review-item" key={idx}>
              <Rating
                className="ratings-star"
                value={_review.rating}
                readonly
              />
              <p>“{_review.message}”</p>
              <div className="review-owner">
                <div
                  className="review-img"
                  style={{
                    backgroundImage: `url(${_review.useravatar})`,
                  }}
                ></div>
                <span>{_review.username}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AiReviewDetails;
