import { useMemo } from "react";
import { useParams } from "react-router-dom";
import AiBot from "../AiBot";
import "./styles.scss";

const RelatedAiList = (props) => {
  const { list } = props;
  const { id } = useParams();

  const aiList = useMemo(() => {
    const filteredList = list.filter((x) => x.id !== +id);
    const newList = filteredList?.length > 4 ? filteredList.slice(0, 4) : filteredList;
    return newList;
  }, [list, id]);

  return (
    <div className="related-ai">
      <h2>Related To Your Search</h2>
      <p>Check out these AI Bots, they may be just what you need.</p>
      <div className="related-ai-list">
        {aiList.map((item) => (
          <div className="related-ai-list--item" key={item.id}>
            <AiBot data={{ ...item }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedAiList;
