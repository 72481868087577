import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getPage } from "@/common/api/contentful.js";
import { useParams } from "react-router-dom";
import ContentfulRenderer from "../../components/ContentfulRenderer";
import useGetLibrary, { CATEGORY_IDS } from "../../common/hooks/useGetLibrary";
import { EXTRA_URLs } from "../../utils/constants";
import Arrow from "@/assets/svgs/arrow";
import HowTaiskWorks from "@/common/components/sections/HowTaiskWorks";
import Subscription from "@/common/components/sections/Subscription";
import RecommendedBots from "./RecommendedBots";
import Library from "../Library";
import InteractiveIcons from "./InteractiveIcons";
import DownloadEmail from "./DownloadEmail";
import FeaturedCreators from "./FeaturedCreators";
import BuildWith from "../../common/components/sections/BuildWith/BuildWith";
import Blog from "../../common/components/sections/Blog";
import { useSelector } from "react-redux";
import PageLoader from "../../common/components/PageLoader";

const FYPContext = React.createContext();

const SUBNAV_CATEGORY = {
  educators: "Education",
  startups: "Entrepreneurship",
  nonProfits: "Ai For Non Profits",
};

export const FYPProvider = ({ children }) => {
  const categories = useSelector((state) => state.marketplace.categories);
  const { category } = useParams();
  const [pageContent, setPageContent] = useState({});
  const [loading, setLoading] = useState(false);
  const getList = useGetLibrary({ category });

  const fetchPageContent = useCallback(async (category) => {
    const res = await getPage(category);
    setPageContent(res);
    setLoading(false);
  }, []);
  const subnav = SUBNAV_CATEGORY[category];
  const subNavData = useMemo(() => {
    if (!categories?.[subnav]) return {};
    return categories[subnav];
  }, [categories, category]);

  useEffect(() => {
    setLoading(true);
    fetchPageContent(category);
    getList();
  }, [category]);

  return (
    <FYPContext.Provider
      value={{
        category,
        pageContent,
        subNavData,
        subnav,
        loading,
      }}
    >
      {children}
    </FYPContext.Provider>
  );
};

const startedBtn = (
  <a href={EXTRA_URLs.GET_STARTED_PATH} className="inline-flex pt-8">
    <div className="flex bg-sitePrimary hover:bg-primary hover:text-sitePrimary text-white text-lg p-4 items-center rounded-md gap-4 justify-between transition-all duration-300">
      Apply now <Arrow className="h-4 w-4" />
    </div>
  </a>
);

export const ColumnContent = ({ left, right, showStartedOnLeft }) => {
  return (
    <div
      className={`flex flex-col gap-8 mb-16 lg:flex-row lg:items-center mt-2 ${!showStartedOnLeft ? "" : "flex-col-reverse"}`}
    >
      <div className="flex-1">
        <ContentfulRenderer data={left.content}>
          {showStartedOnLeft ? startedBtn : null}
        </ContentfulRenderer>
      </div>
      <div className="flex-1">
        <ContentfulRenderer data={right.content}>
          {!showStartedOnLeft ? startedBtn : null}
        </ContentfulRenderer>
      </div>
    </div>
  );
};

const ForYouPage = () => {
  const {
    pageContent = {},
    category,
    subNavData,
    subnav,
    loading,
  } = useContext(FYPContext);

  if (!pageContent.slug) {
    return <></>;
  }

  const HeroBanner = (
    <div className={`w-full h-[650px] relative `}>
      <img
        src={pageContent.heroBanner}
        alt=""
        className="absolute w-full h-[650px] z-0 bg-right object-cover hidden lg:block"
        style={{
          objectPosition: "80% 0",
        }}
      />
      <div className="bg-[#12062e] absolute w-full h-[650px] z-0 bg-right object-cover block lg:hidden"></div>

      <div className="flex flex-col z-10 relative lg:flex-row py-8 h-[650px] w-full container mx-auto sm:px-0 px-5 m-auto">
        <div className="flex-1 flex justify-center align-center flex-col">
          <h1
            style={{ textShadow: "0 0 4px black" }}
            className="text-6xl text-white mb-4"
          >
            {pageContent.heroTitle}
          </h1>
          <div className="text-white" style={{ textShadow: "0 0 4px black" }}>
            <ContentfulRenderer data={pageContent.heroDescription.content} />
          </div>
        </div>
        <div className="hidden lg:flex-1 lg:block"></div>
      </div>
    </div>
  );

  const PromotionalArea = (
    <div
      className="flex flex-col-reverse lg:flex-row gap-8 bg-yellow rounded-lg"
      style={{
        background: "linear-gradient(45deg, #EEBF3A, transparent)",
      }}
    >
      <div className="flex flex-col pl-4 md:pl-16 align-center justify-center lg:align-start flex-1 w-full md:w-1/2 p-8">
        <ContentfulRenderer data={pageContent.promotionalText?.content} />
        {/* <iframe src="https://cdn.forms-content-1.sg-form.com/13124aa2-3571-11ef-9b57-02fc266ef573" /> */}
        <DownloadEmail data={category} />
        {pageContent.promotionalLink && false ? (
          <a href={pageContent.promotionalLink} className="inline-flex pt-8">
            <div className="flex bg-sitePrimary hover:bg-primary hover:text-sitePrimary text-white text-lg p-4 items-center rounded-md gap-4 justify-between transition-all duration-300"></div>
          </a>
        ) : null}
      </div>
      <div className="flex-1 w-full lg:w-1/3 flex items-center justify-center">
        <img
          src={pageContent.promotionalImage}
          alt=""
          className="max-w-full max-h-[400px] object-contain"
        />
      </div>
    </div>
  );

  if (loading) return <PageLoader />;
  return (
    <div className="min-h-10 lg:my-[128px]">
      {HeroBanner}

      <BuildWith />

      {subNavData.length ? (
        <div className="w-full container mx-auto sm:px-0 px-5 flex flex-col m-auto mt-12">
          <ContentfulRenderer data={pageContent.categoryHeader?.content} />
          <InteractiveIcons list={subNavData} category={subnav} />
        </div>
      ) : (
        <></>
      )}
      <main className="sm:p-14 p-0 w-full flex justify-center ">
        <div className="w-full container mx-auto sm:px-0 px-5">
          <ColumnContent
            left={pageContent.topLeftColumn}
            right={pageContent.topRightColumn}
            showStartedOnLeft
          />
          <ColumnContent
            left={pageContent.middleLeftColumn}
            right={pageContent.middleRightColumn}
          />
          <ColumnContent
            left={pageContent.bottomLeftColumn}
            right={pageContent.bottomRightColumn}
            showStartedOnLeft
          />
          {PromotionalArea}
        </div>
      </main>
      <FeaturedCreators headerText={pageContent.recommendationText?.content} />
    </div>
  );
};

const FYPContainer = (props) => {
  const pricingRef = useRef(null);
  return (
    <FYPProvider>
      <ForYouPage />
      {/* <HowTaiskWorks /> */}
      {/* <Blog /> */}
      <Subscription innerRef={pricingRef} />
    </FYPProvider>
  );
};

export default FYPContainer;
