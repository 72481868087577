import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CheckIcon from "@/common/components/sections/Subscription/CheckIcon.jsx";

export const renderOptions = {
  renderNode: {
    "embedded-asset-block": (node) => {
      return (
        <img
          src={"https:" + node.data.target.fields.file.url}
          className="w-auto h-450 rounded-xl"
        />
      );
    },
    "heading-1": (node) => (
      <h1 className="mb-1 text-5xl font-bold">{node.content[0].value}</h1>
    ),
    "heading-2": (node) => (
      <h2 className="mb-1 text-4xl font-bold">{node.content[0].value}</h2>
    ),
    "heading-3": (node) => (
      <h3 className="mb-1 font-bold text-2xl">{node.content[0].value}</h3>
    ),
    "heading-4": (node) => (
      <h4 className="mb-1 text-2xl">{node.content[0].value}</h4>
    ),
    "heading-5": (node) => (
      <h5 className="mb-1 text-2xl">{node.content[0].value}</h5>
    ),
    paragraph: (node) => {
      // console.log(node);
      return (
        <p className="py-2">
          {node.content.map((x) =>
            x.value ? (
              <span
                className={`${x.marks.some((x) => x.type === "bold") ? "font-bold" : ""} ${x.marks.some((x) => x.type === "italic") ? "italic" : ""} `}
              >
                {" "}
                {x.value}{" "}
              </span>
            ) : (
              <>
                {" "}
                <br />{" "}
              </>
            ),
          )}
        </p>
      );
    },
    marks: {
      bold: (text) => <strong>{text}</strong>,
      italic: (text) => <em>{text}</em>,
    },
    "list-item": (node) => {
      return (
        <li className="flex items-center gap-2 my-3">
          <CheckIcon className={"h-4 w-4"} fill={"#C1FF72"} />
          {node.content.map((x) => documentToReactComponents(x, renderOptions))}
        </li>
      );
    },
  },
};

const ContentfulRenderer = ({ data, children }) => {
  if (!data || !data.length) {
    return <></>;
  }
  return (
    <>
      {data.map((x) => {
        return documentToReactComponents(x, renderOptions);
      })}
      {children}
    </>
  );
};

export default ContentfulRenderer;
