import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import { getCreatorSettings } from "../../common/api/creators";
import PageLoader from "../../common/components/PageLoader";
import ContentfulRenderer from "../../components/ContentfulRenderer";

const Creator = (props) => {
  const { userId, packageId } = props;
  const { data, loading } = useQuery({
    queryKey: ["creator", packageId],
    queryFn: () => getCreatorSettings(userId, packageId),
    options: {
      refetchInterval: 3000000,
      staleTime: 3000000,
    },
  });

  if (loading || !data?.result?.[0]) return <div></div>;
  const { data: content } = data.result[0];
  return (
    <Link to={`/creator/${userId}/${packageId}`}>
      <div className="flex gap-8 cursor-pointer hover:bg-white hover:rounded-md p-4">
        <div className="flex-1 flex justify-center">
          <img src={content.footerImage} className="h-[300px]" />
        </div>
        <div className="flex flex-col w-auto flex-1">
          <h3 className="text-xl font-bold pb-4">{content.bannerText}</h3>
          <p>{content.footerBio}</p>
        </div>
      </div>
    </Link>
  );
};

const FeaturedCreators = (props) => {
  return (
    <div className="bg-[#f2f6ff] pt-16 pb-6">
      <div className="container mx-auto sm:px-0 px-5">
        <ContentfulRenderer data={props.headerText} />
        <div className="pt-4">
          <Creator userId={630} packageId={1} />
          <Creator userId={630} packageId={2} />
          <Creator userId={630} packageId={4} />
        </div>
      </div>
    </div>
  );
};

export default FeaturedCreators;
