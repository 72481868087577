import "./styles.scss";
import { defaultLogoUrl } from "../../../config/constant";
import ownerImg from "../../../assets/images/owner.png";
import { useNavigate } from "react-router-dom";
import Badge from "../Forms/Badge";
import { useSelector } from "react-redux";

const AiBot = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const categories = useSelector((state) => state.marketplace.categories);
  const handleViewAI = (aibot) => {
    navigate(`/aibot/id/${aibot.id}`, { state: { aibot, isPackage: props.isFromCreatorPage ?? false } });
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (
        ((num / 1000000).toFixed(1).replace(/\.0$/, "") * 1).toLocaleString() +
        "M"
      );
    } else if (num >= 1000) {
      return (
        ((num / 1000).toFixed(1).replace(/\.0$/, "") * 1).toLocaleString() + "K"
      );
    } else {
      return num.toLocaleString();
    }
  };

  const getSubMenus = (menu) => {
    return (
      Object.keys(categories).find((cat) =>
        categories[cat].some(
          (category) =>
            category.sub_id === menu.sub_id &&
            category.main_id === menu.main_id,
        ),
      ) || "Just For Fun"
    );
  };
  const author_details = data?.author_details;
  const isAiBot = data?.tags?.toLowerCase() === "ai bot";

  const badgeClassName = !isAiBot
    ? "aibot-preview-badge ai-bot"
    : "aibot-preview-badge";
  return (
    <div
      className="aibot-wrapper"
      onClick={isAiBot ? () => handleViewAI(data) : undefined}
    >
      <div className={badgeClassName}>
        <svg
          width="6"
          height="7"
          viewBox="0 0 6 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3" cy="3.5" r="3" fill="currentColor" />
        </svg>
        <span>{data?.tags}</span>
      </div>

      <div
        className="aibot-preview"
        onClick={() => (isAiBot ? () => handleViewAI(data) : undefined)}
      >
        <img
          title={data?.label}
          src={
            isAiBot
              ? data?.lib_image || data?.chat_logo?.ai || defaultLogoUrl
              : data?.logo || defaultLogoUrl
          }
          alt="ai-bot"
        />
      </div>
      <div className="aibot-title">
        <h2
          title={data?.label || data?.name}
          style={{ cursor: isAiBot ? "pointer" : "default" }}
          className={`${props.hideDescription ? "text-center w-full" : ""}`}
        >
          {data?.label || data?.name}
        </h2>
        {!props.hideCategory ? <Badge text={getSubMenus(data)} /> : null}
      </div>
      <p>{props.hideDescription ? "" : data?.description}</p>
      {props.hideOwner ? null : (
        <>
          <div className="aibot-owner">
            <img src={data?.profile_picture || ownerImg} alt="owner-img" />
            <span>{author_details ? author_details?.name : data?.name}</span>
          </div>
          <span className="aibot-bookmark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000000"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
              />
            </svg>
            <span>{formatNumber(String(data?.downloads))}</span>
          </span>
        </>
      )}
    </div>
  );
};

export default AiBot;

