import Banner1 from "../../assets/images/library/banner/1.png";
import Banner2 from "../../assets/images/library/banner/2.png";
import Banner3 from "../../assets/images/library/banner/3.png";
import Banner4 from "../../assets/images/library/banner/4.png";
import Banner5 from "../../assets/images/library/banner/5.png";
import Banner6 from "../../assets/images/library/banner/6.png";
import Banner7 from "../../assets/images/library/banner/7.png";
import Banner8 from "../../assets/images/library/banner/8.png";
import Banner9 from "../../assets/images/library/banner/9.png";

import Image1 from "../../assets/images/library/1.png";
import Image2 from "../../assets/images/library/2.png";
import Image3 from "../../assets/images/library/3.png";
import Image4 from "../../assets/images/library/4.png";
import Image5 from "../../assets/images/library/5.png";
import Image6 from "../../assets/images/library/6.png";
import Image7 from "../../assets/images/library/7.png";
import Image8 from "../../assets/images/library/8.png";
import Image9 from "../../assets/images/library/9.png";

export const categories = [
  {
    name: "Ai For Non Profits",
    bannerImg: Banner1,
    squareImg: Image1,
    roles: [
      {
        main_id: 4,
        name: "Fundraising and Donor Management",
        sub_id: 1,
      },
      {
        main_id: 4,
        name: "Volunteer Coordination and Engagement",
        sub_id: 2,
      },
      {
        main_id: 4,
        name: "Grant Writing and Management",
        sub_id: 3,
      },
      {
        main_id: 4,
        name: "Program Impact Assessment and Reporting",
        sub_id: 4,
      },
      {
        main_id: 4,
        name: "Community Outreach and Advocacy",
        sub_id: 5,
      },
      {
        main_id: 4,
        name: "Financial Management and Compliance",
        sub_id: 6,
      },
    ],
  },
  {
    name: "Education",
    bannerImg: Banner2,
    squareImg: Image2,
    roles: [
      {
        main_id: 2,
        name: "Adaptive Learning and Assessment Tools",
        sub_id: 1,
      },
      {
        main_id: 2,
        name: "Content Creation",
        sub_id: 2,
      },
      {
        main_id: 2,
        name: "Supportive and Special Education",
        sub_id: 3,
      },
      {
        main_id: 2,
        name: "Student Focussed AI",
        sub_id: 4,
      },
      {
        main_id: 2,
        name: "Education Administration",
        sub_id: 5,
      },
      {
        main_id: 2,
        name: "Comprehensive Teacher Assistance",
        sub_id: 6,
      }
    ],
  },
  {
    name: "Entrepreneurship",
    bannerImg: Banner3,
    squareImg: Image3,
    roles: [
      {
        main_id: 3,
        name: "Financial Management",
        sub_id: 1,
      },
      {
        main_id: 3,
        name: "Learning and Growth",
        sub_id: 2,
      },
      {
        main_id: 3,
        name: "Operations and Efficiency",
        sub_id: 3,
      },
      {
        main_id: 3,
        name: "Product Development and Innovation",
        sub_id: 4,
      },
      {
        main_id: 3,
        name: "Startup Essentials",
        sub_id: 5,
      },
      {
        main_id: 3,
        name: "Sales and Marketing",
        sub_id: 6,
      },
    ],
  }
];
