import React from "react";
import { useDispatch } from "react-redux";
import { setAIBotList } from "@/common/redux/actions/library.js";
import { getLibrary } from "@/common/api/library";

export const CATEGORY_IDS = {
  educators: 2,
  startups: 3,
  personal: 5,
};

export const MAIN_CATEGORY_IDS = {
  educators: 1,
  startups: 2,
  personal: 3,
};

const useGetLibrary = (props) => {
  const dispatch = useDispatch();

  const { category } = props;

  const getList = () => {
    const params = {
      page: 1,
      menu: null,
      perpage: 8,
      sortby: 0,
      subMenu: 0,
    };

    if (CATEGORY_IDS[category]) {
      params.main_id = CATEGORY_IDS[category];
      params.main_category_id = MAIN_CATEGORY_IDS[category];
    }

    getLibrary(params).then((res) => {
      if (res && res?.data) {
        console.log(res)
        setAIBotList(dispatch, res.data);
      }
    });
  };
  return getList;
};

export default useGetLibrary;
