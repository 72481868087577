import "./styles.scss";

const TermsAndConditionPage = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">User Terms and Conditions</h1>
        <h2 className="mb-10">Last Updated: 10th March 2024</h2>

        <h2 className="mt-6 mb-4">1. Introduction</h2>
        <p>
          Welcome to TAISK (the "Service"), an artificial intelligence
          independent development environment (“IDE”) and marketplace offered by
          TAISK LTD, a UK-based company (SC811140) registered at Summit House, 4-5 Mitchell Street,
          Edinburgh, EH6 7BD.
        </p>
        <p className="mt-4">
          This platform facilitates AI creation and sharing for task efficiency.
        </p>

        <h2 className="mt-6 mb-4">2. Acceptance of Terms</h2>
        <p>
          By accessing or using our Service, you acknowledge and agree to these
          Terms. If you disagree with any portion of these Terms, you must
          immediately cease using our Service.
        </p>

        <h2 className="mt-6 mb-4">3. User Responsibilities</h2>
        <p>
          You commit to using our Service legally and ethically. You bear full
          responsibility for all activities under your account and the legality
          of any content you submit.
        </p>
        <p className="mt-4">
          You agree not to use the Service for any unlawful, unethical, or
          morally questionable purposes.
        </p>

        <h2 className="mt-6 mb-4">4. User Content</h2>
        <p>
          You retain your intellectual property rights but grant TAISK an
          irrevocable, perpetual, global, royalty-free licence to use,
          reproduce, adapt, and display your content to operate, market, and
          improve the Service.
        </p>

        <h2 className="mt-6 mb-4">5. Privacy & Data Protection</h2>
        <p>
          TAISK, as the operator of TAISK, is committed to adhering to the
          highest standards of data protection in line with the UK's Data
          Protection Act 2018 and the EU's General Data Protection Regulation
          (GDPR).
        </p>
        <p className="mt-4">
          Our platform is specifically designed with robust technical and
          organisational measures to ensure the secure processing, storage, and
          transmission of personal data. Users of TAISK can trust in our
          rigorous safeguards, reflecting our dual commitment to innovation in
          AI-enhanced education and integrity in data protection.
        </p>
        <p className="mt-4">
          We operate under the principles of transparency, accountability, and
          lawfulness, ensuring that users' rights over their personal data are
          respected and protected at all times.
        </p>

        <h2 className="mt-6 mb-4">6. Encryption & Storage</h2>
        <p>
          We employ end-to-end encryption during data transmission and leverage
          the robust infrastructure of AWS RDS databases for secure cloud
          storage. Your content remains safeguarded at all times. UK-based
          Servers: Our servers reside within the United Kingdom, a jurisdiction
          known for its rigorous data protection laws, specifically the Data
          Protection Act 2018 and the EU's General Data Protection Regulation.
          By hosting data within the UK, we minimise the risks tied to
          international data transfers. As a result, your data enjoys protection
          under laws that champion transparency, accountability, and user rights
          concerning personal data. Access & Audits: TAISK operates under
          stringent access controls. We periodically undergo security audits,
          ensuring that the protective measures in place are not only maintained
          but enhanced when necessary.
        </p>
        <p className="mt-4">
          Data Usage by OpenAI: As confirmed by OpenAI on March 1st, 2023, data
          from API calls, including those from TAISK, is not utilised for model
          training. This assurance means that your training data and credits
          relayed via TAISK remain exclusive and are not employed to refine or
          instruct the machine learning algorithms managed by OpenAI.
        </p>
        <p className="mt-4">
          We are a registered member of the Information Commissioner's Office
          (ICO), a testament to our dedication to data protection and our
          compliance with the UK's data protection standards. As a member of the
          ICO, we are subject to regular checks and audits, ensuring we uphold
          the highest standards of data protection, security, and privacy.
        </p>

        <h2 className="mt-6 mb-4">7. Intellectual Property</h2>
        <p>
          All proprietary rights relating to the Service, its design,
          technology, and associated content, including any registered patents,
          belong exclusively to TAISK Any unauthorised use or reproduction is
          prohibited and may lead to legal action.
        </p>

        <h2 className="mt-6 mb-4">8. Service Availability</h2>
        <p>
          While we aim for continuous uptime, we can't guarantee uninterrupted
          Service due to unforeseen technical issues or scheduled maintenance.
          No compensation will be offered for any interruption.
        </p>

        <h2 className="mt-6 mb-4">9. Limitation of Liability</h2>
        <p>
          To the fullest extent permissible by law, TAISK shall not be liable
          for any indirect, incidental, consequential, or punitive damages
          resulting from the use of or inability to access the Service.
        </p>
        <p className="mt-4">
          By using the Service, users expressly waive any rights they may have
          to initiate or participate in any form of litigation or court
          proceedings against TAISK or its affiliates related to any claims,
          disputes, or controversies arising out of or in connection with TAISK
        </p>
        <p className="mt-4">
          This waiver applies to any claims, whether based in contract, tort,
          statute, fraud, misrepresentation, or any other legal theory.
        </p>
        <p className="mt-4">
          Users acknowledge and agree that their sole and exclusive remedy in
          the event of any dispute with TAISK is to cease using the Service.
        </p>

        <h2 className="mt-6 mb-4">10. Updates to these Terms</h2>
        <p>
          Terms may be updated at our discretion. Users are expected to
          regularly review these terms. Continued use post-update implies
          acceptance.
        </p>

        <h2 className="mt-6 mb-4">11. Dispute Resolution</h2>
        <p>
          All disputes will be subject to UK law and exclusively resolved in its
          courts.
        </p>

        <h2 className="mt-6 mb-4">12. User Accounts</h2>
        <p>
          You must protect your account details and promptly report any
          unauthorised activities.
        </p>
        <p className="mt-4">
          TAISK is not liable for unauthorised access due to user negligence.
        </p>

        <h2 className="mt-6 mb-4">13. Termination</h2>
        <p>
          We reserve an unconditional right to suspend or terminate your access
          for any or no reason, with or without notice.
        </p>

        <h2 className="mt-6 mb-4">14. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless TAISK from all
          liabilities, claims, or costs (including legal fees) arising from your
          misuse, violation of these Terms, or placement of content on the
          Service.
        </p>

        <h2 className="mt-6 mb-4">15. Insurance </h2>
        <p>
          TAISK maintains adequate insurance coverage, including but not
          limited to General Liability Insurance and Cyber Liability Insurance,
          to address potential claims or liabilities arising from the provision
          or use of the Service. Upon request, TAISK can provide a Certificate
          of Insurance (COI) as proof of such coverages.
        </p>
        <p className="mt-4">
          Users agree that in the event of any claim, TAISK's liability is
          limited to the coverage amounts and conditions stipulated by its
          insurance policies.
        </p>

        <h2 className="mt-6 mb-4">16. Disclaimers</h2>
        <p>
          TAISK is provided "as is", without any guarantees or warranties,
          including implied warranties of fitness, merchantability, or
          non-infringement.
        </p>

        <h2 className="mt-6 mb-4">17. Fees, Payment and VAT</h2>
        <p>
          Some features are chargeable. All fees are non-refundable except where
          mandated by law. Payments are processed via Stripe on a monthly basis.
        </p>
        <p className="mt-4">
          Cancellation of subscriptions will not result in immediate
          termination; service will continue until the end of the billing
          period.
        </p>
        <p className="mt-4">
          All transactions on TAISK, operated by TAISK (VAT No. GB 451 1180 33),
          are exclusive of Value Added Tax (VAT) at the applicable rate, unless
          stated otherwise. VAT is charged in accordance with the current
          legislation of the customer's country of residence within the EU.
          Non-EU customers are exempt from VAT but may face local customs duties
          and taxes. Business customers within the EU can provide their VAT
          number for exemption.
        </p>
        <p className="mt-4">
          Prices are subject to change in line with VAT rate adjustments. VAT
          invoices will be provided for all eligible transactions.
        </p>

        <h2 className="mt-6 mb-4">18. Application Accuracy</h2>
        <p>
          While we strive for accuracy, the platform doesn't guarantee 100%
          precision. TAISK assumes no liability for inaccuracies or their
          implications.
        </p>
        <p className="mt-4">
          Users are urged to exercise judgement and not rely solely on the
          Service for critical decisions.
        </p>

        <h2 className="mt-6 mb-4">19. No Resale of Service</h2>
        <p>
          Users may not reproduce, duplicate, copy, sell, resell, or exploit any
          portion of the Service without explicit written consent from TAISK
          Ltd.
        </p>

        <h2 className="mt-6 mb-4">20. Force Majeure</h2>
        <p>
          TAISK will not be responsible for delays or failures in the Service
          due to events beyond its control, including natural disasters,
          strikes, or acts of war.
        </p>

        <h2 className="mt-6 mb-4">21. Age Restrictions</h2>
        <p>
          TAISK is not meant for children under 13, and in line with OpenAI
          policy we require that children ages 13 to 18 obtain parental consent
          before creating accounts on TAISK
        </p>
        <p className="mt-4">
          In circumstances where a teacher desires to share an AI Bot they have
          developed with their pupils, they must utilise the 'Share' function.
          This function necessitates that the pupil enter an Organisation ID and
          Pin Code to access solely the specific AI Bot.
        </p>
        <p className="mt-4">
          The teacher bears full responsibility to ensure that the behaviour
          prompt, training data, and stylisation of the AI Bot are suitable for
          their students.
        </p>
        <p className="mt-4">
          Furthermore, it is imperative for educators to recognise that despite
          these safeguards, the AI may generate outputs that are not suitable
          for all audiences or age groups. Educators should exercise due caution
          in using the AI with students or in classroom settings, being aware of
          the potential for inappropriate content.
        </p>

        <h2 className="mt-6 mb-4">22. Credit Costs and Allowances</h2>
        <p>
          Each subscription type grants users a specific monthly allowance for
          credits to be used with our AI services:
        </p>
        <ul className="dots">
          <li>Starter: 1,000 credits/month.</li>
          <li>Growth: 3,000 credits/month</li>
          <li>Enterprise: 30,000 credits/month.</li>
        </ul>
        <p className="mt-4">
          The number of credits consumed with each interaction depends on the AI
          model chosen by the user:
        </p>
        <ul className="dots">
          <li>GPT 3.5 4k: Deducts 1 credit per interaction.</li>
          <li>GPT 4 Turbo: Deducts 10 credits per interaction.</li>
          <li>Dalle-3: Deducts 20 credits per interaction.</li>
          <li>Gemini Chat: Deducts 3 credits per interaction.</li>
          <li>Perplexity (Online): Deducts 25 credits per interaction.</li>
          <li>Claude OPUS: Deducts 30 credits per interaction.</li>
          <li>Claude HAIKU: Deducts 5 credits per interaction.</li>
          <li>Groq Llama 370B: Deducts 10 credits per interaction.</li>
          <li>Groq Llama 8B: Deducts 1 credit per interaction.</li>
        </ul>
        <p className="mt-4">
          Users see when their monthly credit allowance is nearing exhaustion
          and top-up in batches of 500 credits at a time (for a cost of £8.99 +
          VAT). Any of these top-up credits that are not used will roll over to
          the next month. Once a user has consumed their monthly allowance, they
          will not be able to make additional credits until the beginning of the
          next billing period or unless they purchase additional credit
          allowances.
        </p>

        <h2 className="mt-6 mb-4">23. Application Maintenance & Downtime</h2>
        <p>
          TAISK is committed to ensuring the optimal performance of TAISK
          Regular maintenance is essential to achieve this. While we endeavour
          to conduct all maintenance activities between 12:00 am and 3:00 am,
          there may be instances that necessitate maintenance outside of this
          window.
        </p>
        <p className="mt-4">
          During maintenance periods, users may experience degraded performance,
          partial disruptions, or a total outage of the Service. We appreciate
          your understanding and patience during these essential maintenance
          activities.
        </p>
        <p className="mt-4">
          For transparency, we maintain a public record of our platform's uptime
          and provide advance notifications of planned maintenance work. Users
          can access this information and stay updated by visiting our status
          page at:{" "}
          <a href="https://interactivetutor1.statuspage.io/" target="_blank">
            https://interactivetutor1.statuspage.io/
          </a>
          .
        </p>

        <h2 className="mt-6 mb-4">24. Assignment</h2>
        <p>
          You may not assign or transfer your rights or obligations under these
          Terms to any third party without the prior written consent of TAISK
          Ltd. Any attempted assignment in violation of this provision shall be
          null and void.
        </p>

        <h2 className="mt-6 mb-4">CONTACT US</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          info@TAISKcom. These terms are effective as of 10/05/2024.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditionPage;
