import { MenuItem, Typography } from "@material-tailwind/react";
import "../styles.scss";
import { useLocation } from "react-router-dom";

const NavList = (props) => {
  const { list, isTop = false } = props;

  const location = useLocation();

  const search = new URLSearchParams(location.search);
  const sector = search.has("sector") && search.get("sector");

  const handleNavigation = (menu) => {
    if (!menu?.name || !props?.navigate) return;
    props?.navigate(menu);
  };

  return (
    <ul className="nav-list mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {list?.map((_item) => (
        <Typography
          key={_item?.name}
          as="a"
          onClick={() => handleNavigation(_item)}
          variant="small"
          color="gray"
          className="text-blue-gray-500 w-full"
        >
          <MenuItem className="flex items-center gap-2">
            <span
              className={
                "text-nowrap " + (isTop ? "text-white" : "text-gray-900")
              }
            >
              {_item?.name}
            </span>
          </MenuItem>
        </Typography>
      ))}
    </ul>
  );
};

export default NavList;
