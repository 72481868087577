import "./styles.scss";

const AntiSlaveryPolicy = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">Anti-Slavery and Human Trafficking Policy</h1>
        <h2 className="mb-10">Last Updated: 1st March 2024</h2>

        <h2 className="mt-6 mb-4">1. Introduction</h2>
        <p>
          TAISK is committed to ensuring that our business operations and supply
          chains are free from slavery, human trafficking, and any form of
          forced or compulsory labour. This Anti-Slavery and Human Trafficking
          Policy (the "Policy") sets out the measures we have implemented to
          prevent and address modern slavery and human trafficking within our
          organization and our partners.
        </p>

        <h2 className="mt-6 mb-4">2. Scope</h2>
        <p>
          This Policy applies to all employees, contractors, suppliers, and
          other business partners of TAISK, regardless of their location or
          function. We expect all parties to uphold the principles of this
          Policy and share our commitment to eradicating modern slavery and
          human trafficking.
        </p>

        <h2 className="mt-6 mb-4">3. Policy Statement</h2>
        <ol className="bold">
          <li>
            Compliance with Laws and Regulations: We adhere to all applicable
            laws and regulations, including the Modern Slavery Act 2015, in the
            jurisdictions where we operate. We require our employees,
            contractors, suppliers, and other business partners to do the same.
          </li>
          <li>
            Employment Practices: We ensure that all our employees and
            contractors are hired and employed in accordance with applicable
            labour laws and best practices. We provide fair wages, working
            hours, and benefits, and we prohibit the use of forced, bonded, or
            involuntary labour in any form.
          </li>
          <li>
            Recruitment: We maintain a transparent and ethical recruitment
            process, ensuring that all employees are hired based on merit and in
            compliance with relevant labour laws. We do not charge recruitment
            fees to job applicants, and we verify the age of all employees to
            prevent child labour.
          </li>
          <li>
            Supplier and Partner Due Diligence: We conduct regular risk
            assessments and due diligence on our suppliers and partners to
            ensure they comply with our Policy and applicable laws. We expect
            them to uphold the same high standards as we do and implement
            appropriate measures to prevent modern slavery and human trafficking
            in their operations and supply chains.
          </li>
          <li>
            Reporting and Whistleblowing: We encourage all employees,
            contractors, suppliers, and partners to report any concerns or
            suspicions of modern slavery or human trafficking in our operations
            or supply chains. Reports can be made confidentially, without fear
            of retaliation, through our designated reporting channels.
          </li>
        </ol>

        <h2 className="mt-6 mb-4">4. Training and Awareness</h2>
        <p>
          We provide regular training and raise awareness among our employees,
          contractors, suppliers, and partners on the risks of modern slavery
          and human trafficking, as well as our commitment to preventing and
          addressing these issues in our operations and supply chains.
        </p>

        <h2 className="mt-6 mb-4">5. Monitoring and Review</h2>
        <p>
          We regularly review and monitor the effectiveness of our Policy and
          related procedures to ensure their continued relevance and adequacy in
          preventing and addressing modern slavery and human trafficking. We
          will update this Policy as needed to reflect changes in our
          operations, industry best practices, or applicable laws and
          regulations.
        </p>

        <h2 className="mt-6 mb-4">6. Responsibilities</h2>
        <p>
          Our management team is responsible for implementing and enforcing this
          Policy, as well as ensuring that all employees, contractors,
          suppliers, and partners understand and adhere to its principles. All
          employees and contractors are responsible for acting in accordance
          with this Policy and reporting any concerns or suspicions of modern
          slavery or human trafficking in our operations or supply chains.
        </p>

        <h2 className="mt-6 mb-4">7. Contact Information</h2>
        <p>
          If you have any questions or concerns regarding this Policy or suspect
          any form of modern slavery or human trafficking in connection with
          TAISK, please contact [email address] or [postal address]. By working
          together, we can make a meaningful impact in the global effort to
          eradicate modern slavery and human trafficking.
        </p>
      </div>
    </div>
  );
};

export default AntiSlaveryPolicy;
