import { useMemo, useState } from "react";
import List from "./components/List";
import "./styles.scss";
import PageLoader from "../PageLoader";
import { useSelector } from "react-redux";

const AiLibrary = (props) => {
  const { page, pageCount, isLoading, showPagination = true } = props;

  const [currentPage, setCurrentPage] = useState(page);

  const aibots = useSelector((state) => state.library.aibots);
  const list = useMemo(() => {
    return aibots;
  }, [aibots]);

  const handleList = (page) => {
    setCurrentPage(page);
    props?.loadList(page);
  };

  return (
    <div className="ai-library-container">
      <div className="container mx-auto sm:px-0 px-5">
        <h2 className="title" style={{ fontSize: '30px' }}><strong>Popular AI</strong></h2>
        <p style={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          color: '#12062e',
          marginBottom: '1rem'
        }}>Check out these AI built by TAISK subscribers and free to start using</p>
        {isLoading ? (
          <PageLoader />
        ) : (
          <List
            list={list}
            currentPage={currentPage}
            pageCount={pageCount}
            pageChange={handleList}
            hidePagination={!showPagination}
          />
        )}
      </div>
    </div>
  );
};

export default AiLibrary;
