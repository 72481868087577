import { combineReducers } from "@reduxjs/toolkit";
import marketplaceReducer from "./marketplace";
import libraryReducer from "./library";

const mainReducer = combineReducers({
  marketplace: marketplaceReducer,
  library: libraryReducer
});

export default mainReducer;
