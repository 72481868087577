import "./styles.scss";

const EUAiAct = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">EU AI Act Compliance Policy for TAISK</h1>
        <h2 className="mb-10">Last Updated: 14th August 2024</h2>

        <h2 className="mt-6 mb-4">Introduction</h2>
        <p>
          TAISK is committed to aligning its AI solutions, including custom AI
          chatbots and agents, with the European Union's AI Act, ensuring our
          technologies promote human dignity, rights, and trust. This policy
          outlines our approach to complying with the key provisions of the EU
          AI Act, focusing on risk-based classification, territorial scope,
          implementation timeline, compliance, governance, and support for
          innovation.
        </p>

        <h2>Risk-Based Classification Compliance</h2>

        <p>
          Prohibited Risk AI Systems: TAISK acknowledges the prohibition of AI
          systems that pose unacceptable risks. We ensure our solutions do not
          engage in cognitive behavioral manipulation, social scoring, biometric
          identification, or any other practices banned under the Act from 1
          February 2025.
        </p>

        <p>
          High-Risk AI Systems: For AI solutions applicable to education,
          employment, law enforcement, and critical infrastructure, TAISK
          commits to pre-market assessments and continuous lifecycle evaluation
          to mitigate negative effects on safety or fundamental rights.
        </p>

        <p>
          AI Systems Triggering Transparency Obligations: TAISK ensures that our
          generative AI models, including AI chatbots and agents, comply with
          required transparency measures, clearly disclosing when content is
          AI-generated and implementing safeguards against the creation of
          illegal content.
        </p>
        <p>
          General Purpose AI Systems: We will conduct thorough evaluations of
          our AI systems that could pose systemic risks, adhering to reporting
          obligations for any serious incidents.
        </p>

        <h2 className="mt-6 mb-4">
          Territorial Scope and Implementation Timeline
        </h2>
        <p>
          TAISK recognises that the EU AI Act applies to our operations within
          the EU, affecting users within the EU, or utilising AI outputs within
          the EU. We are dedicated to full compliance by the respective
          deadlines: general compliance by 1 August 2026, with a prohibition on
          specific AI systems starting from 1 February 2025. TAISK will monitor
          and adapt to the phased implementation timeline as required.
        </p>

        <h2 className="mt-6 mb-4">Compliance and Governance</h2>
        <p>TAISK will establish a robust governance framework to:</p>
        <ul>
          <li> * Ensure identification of covered AI uses.</li>
          <li>
            {" "}
            * Identify and terminate any prohibited AI practices within six
            months.
          </li>
          <li>
            {" "}
            * Regularly evaluate our AI solutions to guarantee compliance with
            the EU AI Act across their lifecycle.
          </li>
          <li>
            {" "}
            * Appoint a dedicated compliance officer to oversee AI Act
            adherence.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">
          Continuous Monitoring and Support for Innovation
        </h2>
        <p>TAISK will:</p>
        <ul>
          <li>
            {" "}
            * Regularly monitor guidance from the European Commission and the
            European AI Office for updates on the regulatory landscape.
          </li>
          <li>
            {" "}
            * Engage with national authorities to utilise testing environments
            for our AI models, assuring they meet real-world conditions before
            public release.
          </li>
          <li>
            {" "}
            * Foster an environment of innovation while ensuring our AI
            solutions are trustworthy and respect user rights.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Conclusion</h2>
        <p>
          TAISK is dedicated to implementing these measures to ensure our AI
          chatbots and agents not only comply with the EU AI Act but also lead
          the way in ethical AI development and deployment. Our commitment to
          innovation, paired with strict adherence to regulatory requirements,
          positions us to provide AI solutions that are beneficial, safe, and
          respectful of fundamental rights and freedoms.
        </p>
      </div>
    </div>
  );
};

export default EUAiAct;
