import { useMemo } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { defaultLogoUrl } from "../../../config/constant";
import { getPlugins } from "../../api/contentful";
import { useQuery } from "@tanstack/react-query";

const PluginItem = ({ data, onClick }) => {
  return (
    <div className="aibot-preview shadow-md bg-gray-50" onClick={onClick}>
      <div className="aibot-image flex">
        <img className="my-auto" src={data?.itemImage || defaultLogoUrl} alt="ai-icon" />
      </div>
      <div className="aibot-preview-info">
        <h2>{data?.itemTitle}</h2>
        <p>{data?.itemDescription}</p>
      </div>
    </div>
  );
};

const PopularAIBot = () => {
  const navigate = useNavigate();

  const aibots = useSelector((state) => state.library.aibots);
  const pluginsQuery = useQuery({
    queryKey: ["plugins"],
    queryFn: () => getPlugins(),
    options: {
      refetchInterval: 3000000,
      staleTime: 3000000,
    },
  });

  const list = useMemo(() => {
    // if (pluginsQuery.isLoading)
    return pluginsQuery.data || [];
    return aibots || [];
  }, [pluginsQuery.data]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 960 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 960, min: 720 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };

  const handleViewAI = (aibot) => {
    navigate(`/aibot/id/${aibot.id}`, { state: { aibot } });
  };

  const handleItemClick = (slug) => {
    navigate(`plugin/${slug}`);
  };

  const Item = ({ info }) => (
    <div className="aibot-preview shadow-md">
      {info?.tags && (
        <div className="aibot-preview-badge">
          <svg
            width="6"
            height="7"
            viewBox="0 0 6 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="3" cy="3.5" r="3" fill="#12062E" />
          </svg>
          <span>{info?.tags}</span>
        </div>
      )}
      <div className="aibot-image">
        <img
          src={info?.lib_image || defaultLogoUrl}
          alt="ai-icon"
          onClick={() => handleViewAI(info)}
        />
      </div>
      <div className="aibot-preview-info" onClick={() => handleViewAI(info)}>
        <h2>{info?.label}</h2>
        <p>{info?.description}</p>
      </div>
    </div>
  );

  return (
    <div className="popular-aibot-section">
      <div className="container mx-auto sm:px-0 px-5">
        <h2>Join thousands of subscribers using TAISK to automate their tasks</h2>
        <p>
          Build, use and sell AI with the latest large language models, powerful tools and dozens of plugins for apps you use everyday. Get started for free!
        </p>

        {!!list.length && (
          <Carousel
            draggable={false}
            responsive={responsive}
            infinite={true}
            containerClass="popular-aibot-carousel carousel-container mb-10"
            itemClass="px-1"
          >
            {list?.map((_item, idx) => (
              <PluginItem
                data={_item}
                key={idx}
                onClick={() => handleItemClick(_item.slug)}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default PopularAIBot;
