import "./styles.scss";
import DPA_img from "../../assets/images/DPA_img.jpg";

const DataProtectionAgreement = () => {
  return (
    <div className="policy-page">
      <div className="container mx-auto sm:px-0 px-5 py-10">
        <h1 className="mb-10">Data Protection Agreement</h1>
        <h2 className="mb-10">Last Updated: 1st March 2024</h2>

        <h2 className="mt-6 mb-4">Recitals</h2>
        <p>
          This Data Processing Agreement ("Agreement") is automatically
          applicable and sets out the terms and conditions under which the Data
          Processor (the School) shall process personal data upon using TAISK,
          accessed via the Wonde API. Usage of TAISK signifies the School's
          acceptance and adherence to this Agreement, ensuring compliance with
          the UK's Data Protection Act 2018, the EU General Data Protection
          Regulation (GDPR), and other pertinent data protection laws.
        </p>

        <h2 className="mt-6 mb-4">1. Definitions and Interpretations</h2>
        <p>
          In this Agreement, the following terms shall have the meanings
          ascribed to them below:
        </p>
        <ul className="dots">
          <li>
            "Data Subjects" refers to individuals whose personal data is
            processed under this Agreement, encompassing students, staff, and
            others linked with the School.
          </li>
          <li>
            "Processing" encompasses any operation performed on personal data,
            such as collection, storage, utilisation, and transmission.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">2. Data Processing Obligations</h2>
        <ul className="dots">
          <li>
            The Data Processor commits to processing Personal Data exclusively
            for delivering educational services through TAISK.
          </li>
          <li>
            The Data Processor shall ensure the trustworthiness of all staff
            accessing the Personal Data.
          </li>
          <li>
            Data shall be processed in concordance with the procedures detailed
            in Annex 1 of this Agreement.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">3. Technical and Organisational Measures</h2>
        <ul className="dots">
          <li>
            The Data Processor is obliged to enact measures as delineated in
            Annex 2, guaranteeing data security, including safeguards against
            unauthorised or unlawful processing, accidental loss, destruction,
            or harm.
          </li>
          <li>
            TAISK confirms its servers, situated within the United Kingdom,
            adhere to GDPR stipulations.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">4. Rights of Data Subjects</h2>
        <ul className="dots">
          <li>
            The Data Processor shall assist the Data Controller in enabling data
            subjects' rights under the GDPR and applicable legislations.
          </li>
          <li>
            The Data Processor must inform the Data Controller if it receives
            any requests from data subjects regarding their personal data.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">5. Data Breach Notification</h2>
        <ul className="dots">
          <li>
            Upon detecting a personal data breach, the Data Processor is
            required to inform the Data Controller promptly.
          </li>
          <li>
            The notification shall encompass details of the breach, the types
            and estimated number of data subjects and records impacted, and the
            anticipated repercussions.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">6. Sub-processing</h2>
        <ul className="dots">
          <li>
            The Data Processor is prohibited from subcontracting any processing
            operations performed on behalf of the Data Controller under this
            Agreement without the Data Controller's written consent.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">7. Audit and Inspection</h2>
        <ul className="dots">
          <li>
            The Data Controller reserves the right to conduct audits and
            inspections to verify adherence to this Agreement and data
            protection statutes.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">8. Indemnification and Liability</h2>
        <ul className="dots">
          <li>
            Both parties agree to indemnify one another against all claims,
            demands, damages, costs, penalties, and liabilities arising from any
            breach of this Agreement.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">9. Duration and Termination</h2>
        <ul className="dots">
          <li>
            This Agreement is effective from account creation and will remain in
            force until terminated by either party with by cancellation of
            subscription or by written notice.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">10. General Terms</h2>
        <ul className="dots">
          <li>
            Modifications to this Agreement must be in written form and endorsed
            by both parties.
          </li>
          <li>
            This Agreement shall be governed and construed in accordance with
            the UK's Data Protection Act 2018 and the EU GDPR.
          </li>
        </ul>

        <h2 className="mt-6 mb-0">Data Protection Agreement</h2>
        <h2 className="mt-0 mb-4">Annex 1: Data Processing Details</h2>

        <h2 className="mt-6 mb-4">Nature and Purpose of Processing</h2>
        <ul className="dots">
          <li>
            Personal data is processed for the purpose of providing
            administration and support services through TAISK. This includes,
            but is not limited to, report writing, progress tracking, and
            educational assessments.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Types of Personal Data Processed</h2>
        <ul className="dots">
          <li>
            Student Data: Names, age, class, academic records, and learning
            progress.
          </li>
          <li>
            Staff Data: Names, contact details, and professional information.
          </li>
          <li>
            Usage Data: Information on how TAISK is used by students and staff.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Categories of Data Subjects</h2>
        <ul className="dots">
          <li>Students enrolled in the School.</li>
          <li>Teachers and other staff members employed by the School.</li>
        </ul>

        <h2 className="mt-6 mb-4">Data Processing Activities</h2>
        <ul className="dots">
          <li>
            Collection: Gathering data from the School's records to make
            available to schools via TAISK.
          </li>
          <li>
            Storage: Holding data in secure cloud storage provided by AWS RDS
            databases.
          </li>
          <li>
            Access: Data is accessed by authorised personnel of TAISK T/O TAISK
            and the School for administration and support purposes.
          </li>
          <li>
            Analysis: Data is analysed to improve educational content and
            personalise learning experiences.
          </li>
          <li>
            Retention: Data is retained as long as the API is linked or as
            required by law.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Duration of Processing</h2>
        <ul className="dots">
          <li>
            Personal data will be processed as long as the Agreement is in force
            or until the data is no longer needed for its intended purpose.
          </li>
        </ul>

        <h2 className="mt-6 mb-0">Data Protection Agreement</h2>
        <h2 className="mt-0 mb-4">
          Annex 2: Technical and Organisational Measures
        </h2>

        <h2 className="mt-6 mb-4">Data Security Measures</h2>
        <ul className="dots">
          <li>Use of end-to-end encryption for data in transit and at rest.</li>
          <li>Secure cloud storage solutions with AWS RDS databases.</li>
          <li>
            Regular security audits and penetration testing to identify and
            mitigate risks.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Access Control</h2>
        <ul className="dots">
          <li>
            Access to personal data is limited to authorised personnel only.
          </li>
          <li>Use of strong authentication and access control mechanisms.</li>
          <li>Regular review and update of access rights.</li>
        </ul>

        <h2 className="mt-6 mb-4">Data Integrity and Resilience</h2>
        <ul className="dots">
          <li>Regular backups of personal data.</li>
          <li>
            Mechanisms to ensure the ongoing confidentiality, integrity,
            availability, and resilience of processing systems and services.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Incident Response and Management</h2>
        <ul className="dots">
          <li>
            Established incident response plan for managing data breaches.
          </li>
          <li>
            Regular training for staff on recognising and responding to security
            incidents.
          </li>
          <li>Employee Training and Awareness</li>
          <li>
            Regular data protection and security training for all employees.
          </li>
          <li>
            Promoting a culture of data privacy and security within the
            organisation.
          </li>
        </ul>

        <h2 className="mt-6 mb-4">Compliance Monitoring</h2>
        <ul className="dots">
          <li>
            Regular audits to ensure compliance with data protection laws and
            policies.
          </li>
          <li>Documentation and logging of processing activities.</li>
          <li>ICO auditing.</li>
        </ul>

        <h2 className="mt-6 mb-0">Data Protection Agreement</h2>
        <h2 className="mt-0 mb-4">Annex 3. Data Map</h2>

        <p>
          In our dedication to transparency and adherence to data protection
          regulations, we are pleased to present the TAISK Data Map. This
          comprehensive overview has been crafted to offer a clear and
          structured insight into the management of data within the TAISK
          platform.
        </p>

        <p className="mt-6">
          The data map delineates the journey of data from its initial
          acquisition to its eventual stage of retention or erasure. It details
          the types of data we manage, encompassing both personal and
          educational information, and elucidates our data processing activities
          which are fundamental to providing a tailored educational experience.
        </p>

        <p className="mt-6">
          Furthermore, our data map illuminates the storage, access, and
          transfer protocols of the data, ensuring that all stakeholders are
          thoroughly informed about our practices in handling data. This is of
          paramount importance for maintaining trust and integrity, especially
          in the educational sector where the sensitivity of data is of utmost
          concern.
        </p>

        <p className="mt-6">
          The ensuing table encapsulates the key elements of our data management
          process, offering a lucid and concise depiction of our data lifecycle:
        </p>

        <img className="mt-6 mb-6" src={DPA_img} alt="DPA_img" />

        <p>
          This data map is a vital component of our strategy for data
          governance, affirming our commitment to managing all information
          responsibly and in line with legal and ethical standards. We regularly
          revise and update this map to ensure it remains in sync with any
          modifications in our data processing practices or changes in
          regulatory requirements.
        </p>
      </div>
    </div>
  );
};

export default DataProtectionAgreement;
