import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";

import "./styles.scss";
import { getLibrary } from "../../common/api/library";
import List from "../../common/components/AiLibrary/components/List";
import PageLoader from "../../common/components/PageLoader";
import { EXTRA_URLs } from "../../utils/constants";

const SearchPage = () => {
  const { search } = useParams();

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!search) {
      navigate("/");
      return;
    }
    getList(1, search);
  }, [search]);

  const handleLoadList = (page) => {
    setCurrentPage(page);
    getList(page, search);
  };

  const handleGetStarted = () => {
    window.location.href = EXTRA_URLs.GET_STARTED_PATH;
  };

  const getList = (page, search) => {
    setIsLoading(true);
    const params = {
      page: page ?? 1,
      menu: null,
      perpage: 8,
      sortby: 0,
      subMenu: 0,
      search,
    };
    getLibrary(params)
      .then((res) => {
        if (res && res?.data) {
          setList(res.data);
          setPageCount(res.pageCount);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="library-search-page">
      <div className="container mx-auto sm:px-0 px-5 pt-10">
        <h2>Results for: “{search}”</h2>
      </div>

      <div className="ai-library-container">
        <div className="container mx-auto sm:px-0 px-5">
          {isLoading ? (
            <PageLoader />
          ) : Boolean(list?.length) ? (
            <List
              list={list}
              currentPage={currentPage}
              pageCount={pageCount}
              pageChange={handleLoadList}
              hidePagination={false}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full mt-[5rem] mb-[4rem]">
              <LightBulbIcon className="w-16 h-16" />
              <p className="text-lg font-medium text-center mt-4">
                "Nothing here yet, why not build your own?"
              </p>
              <Button
                className="ai-tabs-btn w-fit flex mt-6 md:flex-none flex-1 justify-center normal-case gap-x-3 items-center text-white font-medium text-[14px] md:text-[16px] py-3 px-5 bg-[#12062E] rounded-md"
                onClick={handleGetStarted}
              >
                Get started for free
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
