import "./styles.scss";

const AboutUsPage = () => {
  return (
    <>
      <div className="aboutus-page">
        <div className="aboutus-banner">
          <div className="container mx-auto">
            <h2>About Us</h2>
            <div className="ml-5 mb-1">
              <svg
                fill="#c1ff72"
                preserveAspectRatio="none"
                data-bbox="30.5 43.999 139 112"
                viewBox="30.5 43.999 139 112"
                height="50"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                data-type="shape"
                role="presentation"
                aria-hidden="true"
                aria-label=""
              >
                <g>
                  <path d="M84.463 44.235c2.428 4.024 4.786 8.047 7.352 12.348-1.179.694-2.358 1.457-3.537 2.081-9.294 5.411-18.242 11.169-25.525 19.355-7.422 8.325-11.028 18.037-12.069 29.344.971 0 1.942-.069 2.913 0 5.757.416 11.653.139 17.202 1.457 13.109 3.052 18.103 13.111 17.202 25.39-.902 11.724-10.612 21.159-22.126 21.713-8.462.416-16.577-.763-23.236-6.59-6.034-5.272-8.74-12.418-10.474-19.979-4.995-22.337 1.456-41.414 16.577-57.994 7.63-8.325 16.508-15.123 26.08-21.02 3.121-1.942 6.104-4.024 9.225-6.105h.416z"></path>
                  <path d="M162.148 44c2.428 4.024 4.786 8.047 7.352 12.348-1.179.694-2.358 1.457-3.537 2.081-9.294 5.411-18.242 11.169-25.525 19.355-7.422 8.325-11.028 18.037-12.069 29.344.971 0 1.942-.069 2.913 0 5.757.416 11.653.139 17.202 1.457 13.109 3.052 18.103 13.111 17.202 25.39-.902 11.724-10.612 21.159-22.126 21.713-8.462.416-16.577-.763-23.236-6.59-6.034-5.272-8.74-12.418-10.474-19.979-4.994-22.338 1.457-41.415 16.577-57.995 7.63-8.325 16.508-15.123 26.08-21.02 3.121-1.942 6.104-4.024 9.225-6.105l.416.001z"></path>
                </g>
              </svg>
            </div>
            <p>
              Our mission is to save users over one million hours 
             by using AI to help them with their tasks
            </p>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="aboutus-content">
            <h2>About Us</h2>
            <div className="content">
              <p className="text-lg">
                We are TAISK, an Edinburgh-based company with a mission to save
                you time, by getting AI to complete your tasks.
              </p>
              <p className="text-lg mt-6">
                Our user-friendly platform enables you to create AI Bots
                effortlessly, connect them, and extend their capabilities with a
                variety of plugins, transforming them into powerful AI Agents
                that can complete tasks automatically.
              </p>
              <p className="text-lg mt-6">
                Initially, we launched Interactive Tutor for schools, which
                rapidly gained popularity among educators eager to integrate
                cutting-edge technology into their teaching methods and reduce
                workloads. As we expanded, we recognised that our tools could
                serve a much broader audience. Today, we cater to entrepreneurs,
                small businesses, NGOs, and freelancers who aim to enhance their
                productivity through custom AI automations.
              </p>
              <p className="text-lg mt-6">
                At TAISK, we understand that technology is perpetually evolving,
                and we are dedicated to providing tools that adapt to these
                changes. Our mission is to ensure that individuals and
                organisations at all levels can leverage the power of AI,
                regardless of their technical expertise, and complete tasks
                without the need to be physically present at their desks.
              </p>
              <p className="text-lg mt-6">
                Our vision is to create a future where everyone has access to
                the tools they need to thrive. Whether you're in education,
                business, or the creative field, our platform is designed to
                help you achieve more with less effort.
              </p>
              <p className="text-lg mt-6">
                With TAISK, you can be part of the AI revolution, unlocking new
                possibilities for your work and life, all while freeing yourself
                from the constraints of the traditional workspace.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
