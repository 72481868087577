import "./styles.scss";
// images
import OpenAiIcon from "../../../../assets/icons/open-ai.png";
import PerplexityIcon from "../../../../assets/icons/perplexity.png";
import AnthropicIcon from "../../../../assets/icons/anthropic.png";
import GeminiIcon from "../../../../assets/icons/gemini.png";
import MetaIcon from "../../../../assets/icons/meta.png";
import GroqIcon from "../../../../assets/icons/groq.png";

const BuildWith = () => {
  return (
    <div className="trusted-section">
      <div className="trusted-list mx-auto">
        <img src={OpenAiIcon} alt="open-ai-icon" />
        <img src={PerplexityIcon} alt="perplexity-icon" />
        <img src={AnthropicIcon} alt="anthropic-icon" />
        <img
          src={GeminiIcon}
          alt="gemini-icon"
          style={{
            position: "relative",
            top: "-2px",
          }}
        />
        <img src={MetaIcon} alt="meta-icon" />
        <img src={GroqIcon} alt="groq-icon" />
      </div>
    </div>
  );
};

export default BuildWith;
