import { HttpMethod, apiFetch } from "./helper";

export const getTestimonials = () => {
  return apiFetch(`/api/marketplace/testimonials`, {
    method: HttpMethod.GET
  });
};

export const subscribeToMarketplace = (data) => {
  return apiFetch(`/api/marketplace/add-email-to-sendgrid`, {
    method: HttpMethod.POST,
    data
  });
};

export const getCategoriesMenu = () => {
  return apiFetch(`/api/marketplace/categories`, {
    method: HttpMethod.GET
  });
};

export const emailToDownloadGuide = (data) => {
  return apiFetch(`/api/marketplace/add-email-to-download-guide`, {
    method: HttpMethod.POST,
    data
  });
};

export const emailToContactUs = (data) => {
  return apiFetch(`/api/marketplace/contact-us`, {
    method: HttpMethod.POST,
    data
  });
};