import React, { useState, useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/outline";

const Image = (props) => {
  const { className, imgUrl } = props;

  const [imgLoaded, setImgLoaded] = useState(false);

  const onLoad = () => {
    setImgLoaded(true);
  };

  return (
    <>
      {!imgLoaded && <PhotoIcon className={className} />}
      <img
        className={className}
        style={imgLoaded ? {} : { display: "none" }}
        src={imgUrl}
        onLoad={onLoad}
      />
    </>
  );
};

export default Image;
