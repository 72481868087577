import React from "react";
import { getImageURL } from "../../common/api/contentful";

const HeaderBanner = (props) => {
  const { title, subtitle, backgroundImage } = props.fields;

  const bgImage = getImageURL(backgroundImage);

  return (
    <div className="relative h-[500px] w-full flex">
      <img
        className="absolute h-[500px] w-full object-cover z-0"
        src={bgImage}
        alt=""
      />

      <div className="flex flex-1 flex-col m-auto pt-8 z-10 p-4 lg:p-8">
        <h1
          style={{ textShadow: "0 0 4px black" }}
          className="text-6xl text-white mb-4"
        >
          {title}
        </h1>
        <div className="text-md text-white" style={{ textShadow: "0 0 4px black" }}>
          {subtitle}
        </div>
      </div>
      <div className="flex-1 hidden lg:block"></div>
    </div>
  );
};

const ComponentRenderer = (props) => {
  if (props.sys.contentType.sys.id === "headerBanner") {
    return <HeaderBanner {...props} />;
  }
};

export default ComponentRenderer;
