import { HttpMethod, apiFetch } from "./helper";

export const getCreatorSettings = (userId, packageId) => {
  console.log(userId)
  return apiFetch(`/api/account/get-creators-page?slug=${userId}`, {
    method: HttpMethod.GET,
  });
  return apiFetch(
    `/api/account/get-creators-page?userId=${userId}&packageId=${packageId}`,
    {
      method: HttpMethod.GET,
    },
  );
};

export const getCreatorProfile = (userId) => {
  return apiFetch(`/api/account/get-creators-profile?userId=${userId}`, {
    method: HttpMethod.GET,
  });
};

export const getCreatorsPackages = (userId) => {
  return apiFetch(`/api/account/get-creators-page?userId=${userId}`, {
    method: HttpMethod.GET,
  });
};
