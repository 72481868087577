import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import "./common/scss/app.scss";
import { Provider } from "react-redux";
import { store } from "./common/redux/store";
import ScrollToTop from "./common/components/ScrollToTop";
import AppWrapper from "./common/components/AppWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider className="App" store={store}>
          <AppWrapper>
            <ScrollToTop />
            <AppRoutes />
          </AppWrapper>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
